import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import TableSpinner from "./TableSpinner";
import { getAppUsers } from "../actions/appusers";
import { connect } from "react-redux";
import InterestLabel from "./InterestLabel";

class AppUsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appusers: [],
      meta: {},
      query: {
        search: "",
        sort: "",
        page: 0
      },
      tableSpinner: true
    };
    this.options = {
      page: this.state.meta.current_page,
      paginationSize: this.state.meta.per_page,
      totalSize: this.state.meta.total,
      pageStartIndex: 1,
      hideSizePerPage: true,
      onPageChange: async (page, sizePerPage) => {
        await this.setState({
          query: {
            ...this.state.query,
            page: page
          }
        });

        this.refreshTable();
      }
    };
    this.columns = [
      {
        dataField: "id",
        text: "ID",
        style: {
          width: "60px"
        },
        sort: true,
        onSort: this.orderField,
        sortCaret: this.sortCaret
      },
      {
        dataField: "device",
        width: "200px",
        text: (
          <span>
            <img
              src="images-eve/icon-note.svg"
              alt=""
              className="Listing__view-icon Listing__view-icon_vertical"
            />
            DEVICE
          </span>
        ),
        sort: true,
        onSort: this.orderField,
        sortCaret: this.sortCaret,
        classes: ["title-news__max-width"]
      },
      {
        dataField: "name",
        text: (
          <span>
            <img
              src="images-eve/icon-owner.svg"
              alt=""
              className="Listing__view-icon"
            />
            NAME
          </span>
        ),
        sort: true,
        onSort: this.orderField,
        sortCaret: this.sortCaret
      },
      {
        dataField: "interests",
        classes: ["title-news__max-width"],
        text: (
          <span>
            <img
              src="images-eve/icon-date.svg"
              alt=""
              className="Listing__view-icon"
            />
            INTERESTS
          </span>
        )
      }
    ];
  }

  componentDidMount() {
    this.refreshTable();
  }

  sortCaret = (order, column) => {
    if (order === "asc")
      return (
        <img
          src="images-eve/arrow-listing-top.svg"
          alt=""
          className="Listing__arrow-order"
        />
      );
    else if (order === "desc")
      return (
        <img
          src="images-eve/arrow-listing.svg"
          alt=""
          className="Listing__arrow-order"
        />
      );
    return null;
  };

  orderField = async (field, order) => {
    const prefix = order === "desc" ? "-" : "";
    await this.setState({
      query: {
        ...this.state.query,
        sort: prefix + field
      }
    });
    this.refreshTable();
  };

  searchFor = async term => {
    await this.setState({
      query: {
        ...this.state.query,
        search: term,
        page: 1
      }
    });
    this.refreshTable();
  };

  getQuantity() {
    return this.state.appusers.length;
  }

  refreshTable = () => {
    this.setState({
      appusers: [],
      tableSpinner: true
    });

    this.props
      .getAppUsers(this.state.query)
      .then(response => {
        this.setState({
          tableSpinner: false,
          meta: response.data.meta,
          appusers: response.data.data.map(appuser => {
            return {
              id: appuser.id,
              device: appuser.device,
              name: appuser.name,
              interests: appuser.interests.map(interest => {
                return (
                  <InterestLabel key={interest.name} label={interest.name} />
                );
              })
            };
          })
        });
        this.props.updateQuantity(this.state.meta.total);
      })
      .catch(error => {
        console.error("ERROR", error);
        alert("Hubo un error al cargar la información");
      });
  };

  noDataIndication = () => {
    if (this.state.tableSpinner === true) return <TableSpinner />;

    return <span>No information</span>;
  };

  render() {
    const pagination = {
      ...this.options,
      page: this.state.meta.current_page,
      paginationSize: this.state.meta.per_page,
      totalSize: this.state.meta.total
    };

    return (
      <div className="Listing">
        <div className="table-responsive" style={{ overflow: "hidden" }}>
          <BootstrapTable
            classes="table listing__table"
            remote={{
              pagination: true,
              sort: true,
              filter: true
            }}
            bordered={false}
            onTableChange={(type, fields) => null}
            defaultSorted={this.defaultSorted}
            keyField="id"
            data={this.state.appusers}
            columns={this.columns}
            noDataIndication={this.noDataIndication}
            pagination={paginationFactory(pagination)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getAppUsers
  },
  null,
  {
    forwardRef: true
  }
)(AppUsersTable);
