import React from 'react';
import { Spring } from 'react-spring/renderprops';

class PageTransition extends React.Component {


    render() {
        return (
            <Spring
                from={{ opacity: 0, transform: "translateY(20px)" }}
                to={{ opacity: 1, transform: "translateY(0px)" }}
                delay={300}
            >
                {props => <div style={props}>{this.props.children}</div>}
            </Spring>
        )
    }
}

export default PageTransition;
