import React from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";

import { connect } from "react-redux";
import {
  setNewsBodyEdit,
  removeGifEdit,
  setGifEdit,
  setYesEdit,
  setYesEmojiEdit,
  removeYesEmojiEdit,
  setNoEdit,
  setNoEmojiEdit,
  removeNoEmojiEdit,
  addNewBodyEdit
} from "../../../actions/newsSingle";

import AddGif from "../../../images-eve/add-gif.svg";
import RemoveGif from "../../../images-eve/remove-gif.svg";
import GifPicker from "../../GifPicker";
import RemoveGifBtnWrapper from "../../styles/RemoveGifBtnWrapper";
import AddRemoveGifEmoji from "../../AddRemoveGifEmoji";
import Answer from "../../Answer";
import MessagesListContainer from "../../MessagesListContainer";

const NewsEditTwo = ({
  body,
  gif,
  setNewsBodyEdit,
  removeGifEdit,
  setGifEdit,
  nameYes,
  emojiYes,
  setYesEdit,
  setYesEmojiEdit,
  removeYesEmojiEdit,
  nameNo,
  emojiNo,
  setNoEdit,
  setNoEmojiEdit,
  removeNoEmojiEdit,
  apiNews,
  addNewBodyEdit
}) => {
  const setGifSubmit = gif => {
    setGifEdit(gif);
  };

  return (
    <Row>
      <Col>
        <FormGroup className="my-4">
          <Row form>
            <Col>
              <Label for="body" className="create-news__titles-news">
                CatchApp Messages
              </Label>
            </Col>
            <Col className="text-right">
              <span className="create-news__titles-news">
                {body.length}/140
              </span>
            </Col>
          </Row>
          <Row form>
            <Col>
              <Input
                id="body"
                type="textarea"
                name="body"
                maxLength="140"
                value={body}
                onChange={e => setNewsBodyEdit(e.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup className="my-4">
          <Row form>
            <Col>
              {gif.length <= 0 && (
                <GifPicker
                  image={AddGif}
                  text="Add Gif"
                  setGifSubmit={setGifSubmit}
                />
              )}
              {gif.length > 0 && (
                <RemoveGifBtnWrapper onClick={() => removeGifEdit()}>
                  <AddRemoveGifEmoji image={RemoveGif} text="Remove Gif" />
                </RemoveGifBtnWrapper>
              )}
            </Col>
          </Row>
        </FormGroup>

        <FormGroup className="create-news__titles-news my-4">
          <Row form>
            <Col xs="3">
              <span>Answers</span>
            </Col>
            <Col xs="9">
              <Answer
                letter="Y"
                setAnswer={setYesEdit}
                setEmoji={setYesEmojiEdit}
                removeEmoji={removeYesEmojiEdit}
                text={nameYes}
                emoji={emojiYes}
              />

              <Answer
                letter="N"
                setAnswer={setNoEdit}
                setEmoji={setNoEmojiEdit}
                removeEmoji={removeNoEmojiEdit}
                text={nameNo}
                emoji={emojiNo}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup className="my-4">
          <MessagesListContainer
            apiNews={apiNews}
            addNewBody={addNewBodyEdit}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  body: state.newsSingle.news.body,
  gif: state.newsSingle.news.gif,
  nameYes: state.newsSingle.news.actions[0].name,
  emojiYes: state.newsSingle.emojis.yes,
  nameNo: state.newsSingle.news.actions[1].name,
  emojiNo: state.newsSingle.emojis.no,
  apiNews: state.newsSingle.news.api_news
});

export default connect(
  mapStateToProps,
  {
    setNewsBodyEdit,
    removeGifEdit,
    setGifEdit,
    setYesEdit,
    setYesEmojiEdit,
    removeYesEmojiEdit,
    setNoEdit,
    setNoEmojiEdit,
    removeNoEmojiEdit,
    addNewBodyEdit
  }
)(NewsEditTwo);
