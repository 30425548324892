const initialState = {
  loading: false,
  user: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_USER": {
      return {
        ...state,
        loading: true
      };
    }

    case "GET_USER_SUCCESS": {
      return {
        ...state,
        loading: false,
        user: {
          ...state.user,
          name: action.payload.user.name,
          permissions: [...action.payload.user.permissions],
          avatar: action.payload.user.avatar,
          email: action.payload.user.email,
          role: action.payload.user.role
        }
      };
    }

    default: {
      return state;
    }
  }
};
