const initialState = {
  provinces: {
    data: [],
    loading: false
  },
  locations: {
    data: [],
    loading: false
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "PROVINCES_LOADING": {
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: true
        }
      };
    }

    case "PROVINCES_SUCCESS": {
      return {
        ...state,
        provinces: {
          ...state.provinces,
          loading: false,
          data: action.payload.provinces
        }
      };
    }

    case "LOCATIONS_LOADING": {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: true
        }
      };
    }

    case "LOCATIONS_SUCCESS": {
      return {
        ...state,
        locations: {
          ...state.locations,
          loading: false,
          data: action.payload.locations
        }
      };
    }

    default: {
      return state;
    }
  }
};
