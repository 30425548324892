import { UserAPI } from "../middlewares/api";
import { alertError, alertSuccess } from "../helpers/alerts";
import ErrorReducer from "../helpers/ErrorReducer";

export const getUsers = query => async (dispatch, getState) => {
  const authToken = getState().login.authToken;
  return new Promise((resolve, reject) => {
    UserAPI.userList(authToken, query)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const getUser = id => async (dispatch, getState) => {
  if (getState().login) {
    const authToken = getState().login.authToken;
    dispatch({ type: "LOADING_USER" });
    try {
      const response = await UserAPI.user(authToken, id);

      dispatch({
        type: "GET_USER_SUCCESS",
        payload: {
          user: response.data.user
        }
      });

      return response.status;
    } catch (error) {
      if (error.response.status === 404) {
        alertError(`Sorry, that user doesnt exists.`);
      } else {
        alertError(`Sorry, something bad happened.`);
      }

      return error.response.status;
    }
  }
};

export const newUser = formData => async (dispatch, getState) => {
  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await UserAPI.userNew(authToken, formData);

      if (response && response.status === 200) {
        alertSuccess(`User "${formData.name}" was succefully created.`);
      }

      return response.status;
    } catch (error) {
      if (error.response.status === 422) {
        const errorList = ErrorReducer(error.response.data.errors);
        const errorStringify = errorList.reduce(
          (prev, next) => `${prev}\n${next}`
        );
        alertError(errorStringify);
      } else {
        alertError(`Sorry, something bad happened please try again.`);
      }

      return error.response.status;
    }
  }
};

export const editUser = (id, body) => async (dispatch, getState) => {
  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await UserAPI.userEdit(authToken, id, body);

      if (response && response.status === 200) {
        alertSuccess(`User "${body.name}" was succefully edited.`);
      }

      return response.status;
    } catch (error) {
      if (error.response.status === 422) {
        const errorList = ErrorReducer(error.response.data.errors);
        const errorStringify = errorList.reduce(
          (prev, next) => `${prev}\n${next}`
        );
        alertError(errorStringify);
      } else {
        alertError(`Sorry, something bad happened please try again.`);
      }

      return error.response.status;
    }
  }
};

export const deleteUser = id => async (dispatch, getState) => {
  const authToken = getState().login.authToken;
  return new Promise((resolve, reject) => {
    UserAPI.userDelete(authToken, id)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const editUserAvatar = (id, formData) => async (dispatch, getState) => {
  if (getState().login) {
    const authToken = getState().login.authToken;
    try {
      const response = await UserAPI.userEditAvatar(authToken, id, formData);

      if (response.status === 200) {
        alertSuccess("User avatar was uploaded successfully");
      }
    } catch (error) {
      if (error.response.status === 422) {
        const errorList = ErrorReducer(error.response.data.errors);
        const errorStringify = errorList.reduce(
          (prev, next) => `${prev}\n${next}`
        );
        alertError(errorStringify);
      } else {
        alertError(`Sorry, something bad happened please try again.`);
      }
      return error.response.status;
    }
  }
};
