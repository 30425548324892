import React from "react";
import { Spinner } from "reactstrap";
import { connect } from "react-redux";
import Select from "react-select";

const CategoryFilter = ({
  categories = [],
  loading,
  selectedValues,
  setCategories
}) => {
  const handleChange = options => {
    setCategories(options);
  };

  if (loading)
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner size="sm" color="secondary" />
      </div>
    );

  return (
    <Select
      value={selectedValues}
      options={categories}
      onChange={handleChange}
      placeholder="Select"
      isMulti
      classNamePrefix="select_choose-category"
    />
  );
};

const mapStateToProps = state => ({
  loading: state.common.categories.loading,
  categories: state.common.categories.data
});

export default connect(
  mapStateToProps,
  null
)(CategoryFilter);
