import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col } from "reactstrap";
import TableSpinner from "./TableSpinner";

const SpinnerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class GifPreview extends Component {
  state = {
    loading: true
  };

  handleLoading = () => {
    this.setState({
      loading: false
    });
  };

  render() {
    const { gif } = this.props;
    const { loading } = this.state;

    return (
      <Col xs="12" className="my-3">
        <div
          className="talk-bubble talk-bubble_gif tri-right round btm-left"
          style={{ minHeight: "80px" }}
        >
          {loading && (
            <SpinnerWrapper>
              <TableSpinner />
            </SpinnerWrapper>
          )}

          <img
            src={gif}
            alt="catchapp gif"
            className="create-news__gif-preview"
            onLoad={this.handleLoading}
          />
        </div>
      </Col>
    );
  }
}

GifPreview.propTypes = {
  gif: PropTypes.string.isRequired
};

export default GifPreview;
