import React from 'react';
import PropTypes from "prop-types";

class DropdownButton extends React.Component {
    onButtonClick = event => {
        this.props.onClick(this.props.entity);
    };

    render() {
        return <button onClick={this.onButtonClick} className="dropdown-item dropdown-item__general" type="button"><img
            src={this.props.image} className="icons-popover icons-popover_delete" alt="" />{this.props.label}</button>
    }
}

DropdownButton.propTypes = {
    entity: PropTypes.object,
    label: PropTypes.string.isRequired,
    onButtonClick: PropTypes.func
};

export default DropdownButton;
