import React from "react";

import { withRouter } from "react-router-dom";

import {
  Input,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Spinner
} from "reactstrap";

import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { getUser, editUser, editUserAvatar } from "../actions/users";

import UploadImageProfile from "./UploadImageProfile";
import TableSpinner from "./TableSpinner";
import SubmitButton from "./SubmitButton";
import Checkbox from "./Checkbox";

const permissions = ["Manage News", "Manage Ads", "Manage Users"];

class FormUserEdit extends React.Component {
  state = {
    loadingImage: false
  };

  async componentDidMount() {
    const { userId, getUser, history } = this.props;
    const response = await getUser(userId);

    if (response === 404) {
      history.push("/users");
    }
  }

  loadingImage = () => {
    this.setState({
      loadingImage: !this.state.loadingImage
    });
  };

  onImageChange = async file => {
    const { userId, editUserAvatar } = this.props;

    this.loadingImage();
    const formData = new FormData();
    formData.append("avatar", file ? file : null);

    await editUserAvatar(userId, formData);

    this.loadingImage();
  };

  render() {
    const { loading, user } = this.props;
    const { loadingImage } = this.state;

    if (loading) return <TableSpinner />;

    return (
      <>
        <div className="p-relative">
          {loadingImage && (
            <div className="loading-avatar">
              <Spinner type="grow" color="dark" />
            </div>
          )}
          <UploadImageProfile
            onImageChange={this.onImageChange}
            imagePreview={user.avatar}
          />
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: user.name || "",
            email: user.email || "",
            role: user.role || "",
            permissions: user.permissions || []
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required."),
            email: Yup.string()
              .email()
              .required("Required."),
            role: Yup.string().required("Required.")
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const { editUser, userId, history } = this.props;
            let editedUser = {
              ...values,
              permissions: values.role === "admin" ? [] : values.permissions
            };

            const response = await editUser(userId, editedUser);

            if (response === 200) {
              history.push("/users");
            }

            setSubmitting(false);
          }}
          render={({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset
                disabled={isSubmitting}
                aria-busy={isSubmitting}
                className="FormsGeneral"
              >
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="USERNAME NAME* "
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="FormsGeneral-Inputs"
                  />
                  {errors.name && touched.name && (
                    <FormFeedback className="d-block">
                      {errors.name}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    name="email"
                    type="email"
                    id="email"
                    placeholder="EMAIL*"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="FormsGeneral-Inputs"
                  />
                  {errors.email && touched.email && (
                    <FormFeedback className="d-block">
                      {errors.email}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    type="select"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="FormsGeneral-Select FormsGeneral-Select--Line"
                  >
                    <option value="" disabled={true}>
                      Access Type
                    </option>
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                  </Input>
                  {errors.role && touched.role && (
                    <FormFeedback className="d-block">
                      {errors.role}
                    </FormFeedback>
                  )}
                </FormGroup>
                {values.role === "editor" && (
                  <FieldArray
                    name="permissions"
                    render={arrayHelpers => (
                      <div className="mt-4">
                        {permissions.map(item => (
                          <div key={item}>
                            <Checkbox
                              label={item}
                              value={item}
                              checked={values.permissions.includes(
                                item.toLocaleLowerCase()
                              )}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(item.toLocaleLowerCase());
                                } else {
                                  const idx = values.permissions.indexOf(
                                    item.toLocaleLowerCase()
                                  );
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  />
                )}
                <FormGroup>
                  <FormText color="muted">Required Fields *</FormText>
                </FormGroup>
                <FormGroup>
                  <SubmitButton
                    className="badge badge-pill badge-primary FormsGeneral-Button"
                    disabled={isSubmitting}
                  >
                    SAVE
                  </SubmitButton>
                </FormGroup>
              </fieldset>
            </Form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.users.loading,
  user: state.users.user
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      editUser,
      getUser,
      editUserAvatar
    }
  )(FormUserEdit)
);
