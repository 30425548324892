import React from "react";
import PropTypes from "prop-types";

class InputField extends React.Component {
  state = {
    value: this.props.value
  };

  getValue() {
    return this.refs.inputField.value;
  }

  renderErrors() {
    if (!this.props.errors) return null;
    return this.props.errors.map(error => {
      return (
        <span className="help-block text-danger input-errors">{error}</span>
      );
    });
  }

  onChange = event => {
    this.setState({ value: event.target.value });

    if (this.props.onChange) this.props.onChange(event);
  };

  render() {
    return (
      <div className="form-group has-error">
        <input
          type={this.props.type}
          name={this.props.name}
          className={this.props.className}
          aria-describedby=""
          placeholder={this.props.placeholder}
          ref="inputField"
          value={this.state.value}
          onChange={this.onChange}
        />
        {this.renderErrors()}
      </div>
    );
  }
}

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  errors: PropTypes.array
};

export default InputField;
