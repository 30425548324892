import React from "react";

const AddRemoveGifEmoji = ({ image, text }) => (
  <>
    <img
      src={image}
      alt="add emoji"
      className="create-news__icon-add-gif mr-1"
    />
    <span className="create-news__label">{text}</span>
  </>
);

export default AddRemoveGifEmoji;
