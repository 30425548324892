import React from "react";

const TableSpinner = ({ width = 5, height = 5, margin = 3 }) => (
  <div className="text-center">
    <div
      className="spinner-border"
      style={{
        width: `${width}rem`,
        height: `${height}rem`,
        margin: `${margin}rem`
      }}
      role="status"
    >
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default TableSpinner;
