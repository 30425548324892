import React, { useState } from "react";
import _ from "lodash";
import { Form, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { forgotPassword } from "../../actions/login";

import SubmitButton from "../SubmitButton";

const FormForgotPassword = ({ forgotPassword, history }) => {
  const [fields, setFields] = useState({
    email: ""
  });

  const [errors, setErrors] = useState({
    email: null,
    message: null
  });

  const [loading, setLoading] = useState(false);

  const onHandleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    try {
      await forgotPassword(fields.email);
      history.push("/login/forgot-password/ok");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        _.map(error.response.data.errors, (value, key) => {
          setErrors({
            ...errors,
            [key]: value,
            message: error.response.data.message
          });
        });
      } else {
        setErrors({
          ...errors,
          message: error.message
        });
      }

      setLoading(false);
    }
  };

  return (
    <Form className="FormsGeneral w-100" onSubmit={onHandleSubmit}>
      <Input
        name="email"
        type="text"
        className="form-control FormsGeneral-Inputs"
        aria-describedby=""
        placeholder="EMAIL*"
        value={fields.email}
        onChange={e =>
          setFields({ ...fields, [e.target.name]: e.target.value })
        }
      />
      {errors.email && (
        <span className="help-block text-danger input-errors">
          {errors.email}
        </span>
      )}
      <div className="form-forgot-pass__bottom">
        <span className="FormsGeneral-Required">* Required Field </span>
        {errors.message && (
          <p className="FormsGeneral-Required text-danger">{errors.message}</p>
        )}

        <SubmitButton
          type="submit"
          className="badge badge-pill badge-primary FormsGeneral-Button"
          disabled={loading}
        >
          SEND
        </SubmitButton>
      </div>
    </Form>
  );
};

export default withRouter(
  connect(
    null,
    {
      forgotPassword
    }
  )(FormForgotPassword)
);
