import { combineReducers } from "redux";

import { loginReducer } from "./login";
import common from "./common";
import news from "./news";
import addresses from "./addresses";
import publishers from "./publishers";
import newsSingle from "./newsSingle";
import users from "./users";

export default combineReducers({
  login: loginReducer,
  common,
  news,
  addresses,
  publishers,
  newsSingle,
  users
});
