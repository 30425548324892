const initialState = {
  id: null,
  loading: false,
  newslist: [],
  previousPublishers: [],
  gifList: {
    loading: false,
    data: []
  },
  emojis: {
    yes: "",
    no: ""
  },
  news: {
    title: "",
    headline: "",
    body: "",
    priority: 1,
    frequency: "1",
    starts_at: "",
    ends_at: "",
    active: false,
    gif: "",
    api_news: [],
    categories: [],
    genders: [],
    age_range: [],
    actions: [
      {
        name: "",
        action: "view-more",
        weight: 1,
        emoji: null
      },
      {
        name: "",
        action: "pass",
        weight: -1,
        emoji: null
      }
    ]
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_NEWS_SINGLE_LOADING": {
      return {
        ...state,
        loading: true
      };
    }

    case "SET_PUBLISHER_TO_EDIT": {
      return {
        ...state,
        news: {
          ...state.news,
          api_news: [...state.news.api_news, action.payload.publisher]
        }
      };
    }

    case "DELETE_PUBLISHER_FROM_EDIT": {
      return {
        ...state,
        news: {
          ...state.news,
          api_news: state.news.api_news.filter(
            item => item.id !== action.payload.value
          )
        }
      };
    }

    case "RESET_EDITING": {
      return {
        ...state,
        ...initialState
      };
    }

    case "GET_NEWS_SINGLE_SUCCESS": {
      return {
        ...state,
        loading: false,
        id: action.payload.id,
        previousPublishers: action.payload.api_news,
        news: {
          ...state.news,
          title: action.payload.title,
          headline: action.payload.title,
          categories: [...action.payload.categories],
          priority: action.payload.priority,
          api_news: action.payload.api_news,
          gif: action.payload.gif ? action.payload.gif : "",
          body: action.payload.body,
          actions: [...action.payload.actions],
          genders: [...action.payload.genders],
          starts_at: action.payload.starts_at,
          ends_at: action.payload.ends_at,
          frequency: action.payload.frequency
        },
        emojis: {
          ...state.emojis,
          yes: `0x${action.payload.unicode.yes}`,
          no: `0x${action.payload.unicode.no}`
        }
      };
    }

    case "GET_NEWS_SINGLE_FAILURE": {
      return {
        ...state,
        loading: false
      };
    }

    case "SET_NEWS_SINGLE_TITLE": {
      return {
        ...state,
        news: {
          ...state.news,
          title: action.payload.title,
          headline: action.payload.title
        }
      };
    }

    case "SET_SINGLE_PRIORITY": {
      return {
        ...state,
        news: {
          ...state.news,
          priority: action.payload.value
        }
      };
    }

    case "GET_SINGLE_CATEGORIES": {
      return {
        ...state,
        categories: action.payload.categories
      };
    }

    case "SET_EDIT_CATEGORIES": {
      return {
        ...state,
        news: {
          ...state.news,
          categories: [...action.payload.value]
        }
      };
    }

    case "SET_EDIT_BODY": {
      return {
        ...state,
        news: {
          ...state.news,
          body: action.payload.value
        }
      };
    }

    case "SET_EDIT_GIF": {
      return {
        ...state,
        news: {
          ...state.news,
          gif: action.payload.gif
        }
      };
    }

    case "REMOVE_EDIT_GIF": {
      return {
        ...state,
        news: {
          ...state.news,
          gif: ""
        }
      };
    }

    case "SET_YES_EDIT_TEXT": {
      const yesAction = state.news.actions[0];

      const modifiedYes = {
        ...yesAction,
        name: action.payload.text
      };

      return {
        ...state,
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 0: modifiedYes })
        }
      };
    }

    case "SET_YES_EDIT_EMOJI": {
      const yesAction = state.news.actions[0];

      const modifiedYes = {
        ...yesAction,
        emoji: action.payload.unified
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          yes: `0x${action.payload.unified}`
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 0: modifiedYes })
        }
      };
    }

    case "REMOVE_YES_EDIT_EMOJI": {
      const yesAction = state.news.actions[0];

      const modifiedYes = {
        ...yesAction,
        emoji: null
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          yes: ""
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 0: modifiedYes })
        }
      };
    }

    case "SET_NO_EDIT_TEXT": {
      const noAction = state.news.actions[1];

      const modifiedNo = {
        ...noAction,
        name: action.payload.text
      };

      return {
        ...state,
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 1: modifiedNo })
        }
      };
    }

    case "SET_NO_EDIT_EMOJI": {
      const noAction = state.news.actions[1];

      const modifiedNo = {
        ...noAction,
        emoji: action.payload.unified
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          no: `0x${action.payload.unified}`
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 1: modifiedNo })
        }
      };
    }

    case "REMOVE_NO_EDIT_EMOJI": {
      const noAction = state.news.actions[1];

      const modifiedNo = {
        ...noAction,
        emoji: null
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          no: ""
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 1: modifiedNo })
        }
      };
    }

    case "ADD_BODY_EDIT_TEXT": {
      const apiNewsCopy = state.news.api_news;

      const updatedApisNews = apiNewsCopy.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            body: action.payload.body
          };
        }

        return item;
      });

      return {
        ...state,
        news: {
          ...state.news,
          api_news: [...updatedApisNews]
        }
      };
    }

    case "SET_EDIT_START_AT": {
      return {
        ...state,
        news: {
          ...state.news,
          starts_at: action.payload.value
        }
      };
    }

    case "SET_EDIT_ENDS_AT": {
      return {
        ...state,
        news: {
          ...state.news,
          ends_at: action.payload.value
        }
      };
    }

    case "SET_EDIT_AGE_RANGES": {
      return {
        ...state,
        news: {
          ...state.news,
          age_range: action.payload.value
        }
      };
    }

    case "SET_EDIT_GENDER": {
      return {
        ...state,
        news: {
          ...state.news,
          genders: action.payload.value
        }
      };
    }

    default: {
      return state;
    }
  }
};
