import React, { Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Navbar, Container } from "reactstrap";
import LogoImage from "../../images/header-logo.svg";

import ArrowWhite from "../../images-eve/arrow-white.svg";

const UnauthenticatedHeader = ({ match }) => (
  <Fragment>
    <div className="header-mobile">
      <Row className="m-0">
        <Navbar color="dark" dark expand="sm">
          <Link className="navbar-brand" to="/">
            <img
              className="header-mobile__brand-img"
              src={LogoImage}
              alt="CatchApp"
            />
          </Link>
        </Navbar>
      </Row>
    </div>
    <div className="header-desktop">
      <Row className="m-0">
        <Navbar color="dark" dark expand="sm" className="px-0 w-100">
          <Container className="header-desktop__Container px-0" fluid>
            <div className="AppSidebar-Header" style={{ width: "152px" }}>
              <Link className="navbar-brand d-block AppSidebar-Brand" to="/">
                <img className="d-block" src={LogoImage} alt="iMetriq" />
              </Link>
            </div>

            {match.path === "/login" && !match.isExact && (
              <Link to="/login" className="d-block mr-auto ml-5">
                <img
                  alt="arrow back"
                  src={ArrowWhite}
                  className="mr-4"
                  style={{ width: "30px" }}
                />
              </Link>
            )}
          </Container>
        </Navbar>
      </Row>
    </div>
  </Fragment>
);

export default withRouter(UnauthenticatedHeader);
