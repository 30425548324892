import React, { useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import styled from "styled-components";

import EmojiPicker from "./EmojiPicker";
import AddRemoveGifEmoji from "./AddRemoveGifEmoji";

import AddEmoji from "../images-eve/add-gif.svg";
import RemoveEmoji from "../images-eve/remove-gif.svg";

const PickerWrapper = styled(DropdownMenu)`
  min-width: 300px;
  border-top: 2px solid #f87829;
  margin-top: 2em;
`;

const EmojiWrapper = styled.div`
  font-size: 2em;
  margin: 0 auto;
  text-align: center;
`;

const AddGifBtn = styled(DropdownToggle)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
  }

  &:active {
    background-color: transparent !important;
    border-color: transparent !important;
  }
`;

const AnswerYes = ({
  text,
  emoji,
  setAnswer,
  setEmoji,
  removeEmoji,
  letter
}) => {
  const [open, setOpen] = useState(false);

  const getEmoji = emoji => {
    const emojiCopy = emoji;
    const emojiRaw = emoji.unified.split("-");
    const emojiCode = emojiRaw[0];

    const newEmoji = {
      ...emojiCopy,
      unified: emojiCode
    };

    setEmoji(newEmoji);
    setOpen(!open);
  };

  return (
    <FormGroup className="d-flex">
      <Row noGutters className="w-100 align-items-center">
        <Col xs={1} sm={1}>
          <Label for="yes">{letter}:</Label>
        </Col>
        <Col xs={5} sm={emoji.length > 0 ? 5 : 7}>
          <Input
            id="yes"
            type="text"
            className="create-news__input"
            value={text}
            onChange={e => setAnswer(e.target.value)}
          />
        </Col>
        {emoji.length > 0 && (
          <Col xs={2} sm={2}>
            <EmojiWrapper>{String.fromCodePoint(emoji)}</EmojiWrapper>
          </Col>
        )}
        <Col xs={4} sm={4}>
          {emoji.length > 0 ? (
            <span onClick={() => removeEmoji()} style={{ cursor: "pointer" }}>
              <AddRemoveGifEmoji image={RemoveEmoji} text="Remove Emoji" />
            </span>
          ) : (
            <ButtonDropdown isOpen={open} toggle={() => setOpen(!open)}>
              <AddGifBtn className="add-remove-buttons mx-2 p-2">
                <AddRemoveGifEmoji image={AddEmoji} text="Add Emoji" />
              </AddGifBtn>
              <PickerWrapper>
                <EmojiPicker getEmoji={getEmoji} />
              </PickerWrapper>
            </ButtonDropdown>
          )}
        </Col>
      </Row>
    </FormGroup>
  );
};

export default AnswerYes;
