import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import styled from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "../../../assets/css/datetime.css";
import "react-datepicker/dist/react-datepicker.css";

import { connect } from "react-redux";
import {
  setStartAtEdit,
  setEndsAtEdit,
  setAgeRangeEdit,
  setNewsGenderEdit
} from "../../../actions/newsSingle";

const gendersOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" }
];

const DatePickerWrapper = styled.div`
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const NewsNewThree = ({
  startsAt,
  setStartAtEdit,
  endsAt,
  setEndsAtEdit,
  frequency,
  ageRange,
  ageRangeId,
  setAgeRangeEdit,
  genders,
  setNewsGenderEdit
}) => {
  const selectedGenders = genders.map(item => ({
    value: item,
    label: item.charAt(0).toUpperCase() + item.slice(1)
  }));

  const newStartsAt = startsAt.length > 0 && new Date(startsAt);
  const newEndsAt = endsAt.length > 0 && new Date(endsAt);

  const handleChangeStart = date => {
    setStartAtEdit(date);
  };

  const handleChangeEnds = date => {
    setEndsAtEdit(date);
  };

  return (
    <Row>
      <Col>
        <FormGroup className="my-4">
          <Row form>
            <Col xs={5}>
              <Label
                for="hour"
                className="reate-news__titles-news create-news__titles-preview text-uppercase"
              >
                Starts At
              </Label>
            </Col>
            <Col xs={7}>
              <DatePickerWrapper>
                <DatePicker
                  className="form-control"
                  selected={newStartsAt}
                  startDate={newStartsAt}
                  endDate={newEndsAt}
                  onChange={handleChangeStart}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  dropdownMode="select"
                  timeIntervals={15}
                  selectsStart
                  showTimeSelect
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                />
              </DatePickerWrapper>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup className="my-4">
          <Row form>
            <Col xs={5}>
              <Label
                for="hour"
                className="reate-news__titles-news create-news__titles-preview text-uppercase"
              >
                Ends At
              </Label>
            </Col>
            <Col xs={7}>
              <DatePickerWrapper>
                <DatePicker
                  className="form-control"
                  selected={newEndsAt}
                  startDate={newStartsAt}
                  endDate={newEndsAt}
                  onChange={handleChangeEnds}
                  minDate={newStartsAt}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  dropdownMode="select"
                  timeIntervals={15}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  selectsEnd
                  showTimeSelect
                />
              </DatePickerWrapper>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup className="my-4">
          <Row form className="align-items-center">
            <Col xs={5}>
              <Label
                for="frequency"
                className="reate-news__titles-news create-news__titles-preview text-uppercase"
              >
                Frequency
              </Label>
            </Col>
            <Col xs={7}>
              <Input
                id="frequency"
                type="number"
                value={frequency}
                max="99"
                min="1"
                disabled={true}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup className="my-4">
          <Row form className="align-items-center">
            <Col xs={5}>
              <Label
                for="age_range"
                className="reate-news__titles-news create-news__titles-preview text-uppercase"
              >
                Age Range
              </Label>
            </Col>
            <Col xs={7}>
              <Select
                value={ageRangeId.map(item =>
                  ageRange.find(el => (el.value === item ? item : null))
                )}
                options={ageRange}
                onChange={value => setAgeRangeEdit(value)}
                isMulti
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup className="my-4">
          <Row form className="align-items-center">
            <Col xs={5}>
              <Label
                for="Gender"
                className="reate-news__titles-news create-news__titles-preview text-uppercase"
              >
                Gender
              </Label>
            </Col>
            <Col xs={7}>
              <Select
                value={selectedGenders}
                options={gendersOptions}
                onChange={selectedOption => setNewsGenderEdit(selectedOption)}
                isMulti
              />
            </Col>
          </Row>
        </FormGroup>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  startsAt: state.newsSingle.news.starts_at,
  endsAt: state.newsSingle.news.ends_at,
  frequency: state.newsSingle.news.frequency,
  ageRange: state.common.age_range,
  ageRangeId: state.newsSingle.news.age_range,
  genders: state.newsSingle.news.genders
});

export default connect(
  mapStateToProps,
  {
    setStartAtEdit,
    setEndsAtEdit,
    setAgeRangeEdit,
    setNewsGenderEdit
  }
)(NewsNewThree);
