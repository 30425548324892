import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import swal from "sweetalert";

import TableSpinner from "./TableSpinner";
import { getUsers, deleteUser } from "../actions/users";
import InterestLabel from "./InterestLabel";
import DropdownMenu from "./DropdownMenu";
import DropdownLink from "./DropdownLink";
import DropdownButton from "./DropdownButton";
import { alertError } from "../helpers/alerts";

import ImageDropdownEdit from "../images-eve/edit-popover.svg";
import ImageDropdownDelete from "../images-eve/delete_listing.svg";
import ImageDots from "../images-eve/dots-horizontal.svg";

import ImageTableOwner from "../images-eve/icon-owner.svg";

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      meta: {},
      query: {
        search: "",
        sort: "",
        page: 0
      },
      tableSpinner: true
    };
    this.options = {
      page: this.state.meta.current_page,
      paginationSize: this.state.meta.per_page,
      totalSize: this.state.meta.total,
      pageStartIndex: 1,
      hideSizePerPage: true,
      onPageChange: async (page, sizePerPage) => {
        await this.setState({
          query: {
            ...this.state.query,
            page: page
          }
        });

        this.refreshTable();
      }
    };
    this.columns = [
      {
        dataField: "id",
        text: "ID",
        style: {
          width: "60px"
        },
        sort: true,
        onSort: this.orderField,
        sortCaret: this.sortCaret
      },
      {
        dataField: "name",
        text: (
          <span>
            <img src={ImageTableOwner} alt="" className="Listing__view-icon" />
            NAME
          </span>
        ),
        sort: true,
        onSort: this.orderField,
        sortCaret: this.sortCaret
      },
      {
        dataField: "email",
        width: "200px",
        text: <span>EMAIL</span>,
        sort: true,
        onSort: this.orderField,
        sortCaret: this.sortCaret,
        classes: ["title-news__max-width"]
      },
      {
        dataField: "role",
        classes: ["title-news__max-width"],
        text: <span>USER TYPE</span>
      },
      {
        dataField: "permissions",
        classes: ["title-news__max-width"],
        text: <span>PERMISSIONS</span>
      },
      {
        dataField: "options",
        text: ""
      }
    ];
  }

  componentDidMount() {
    this.refreshTable();
  }

  sortCaret = (order, column) => {
    if (order === "asc")
      return (
        <img
          src="images-eve/arrow-listing-top.svg"
          alt=""
          className="Listing__arrow-order"
        />
      );
    else if (order === "desc")
      return (
        <img
          src="images-eve/arrow-listing.svg"
          alt=""
          className="Listing__arrow-order"
        />
      );
    return null;
  };

  orderField = async (field, order) => {
    const prefix = order === "desc" ? "-" : "";
    await this.setState({
      query: {
        ...this.state.query,
        sort: prefix + field
      }
    });
    this.refreshTable();
  };

  searchFor = async term => {
    await this.setState({
      query: {
        ...this.state.query,
        search: term,
        page: 1
      }
    });
    this.refreshTable();
  };

  getQuantity() {
    return this.state.users.length;
  }

  onUserDelete = entity => {
    swal(`Are you sure you want to delete user ${entity.name}`, {
      buttons: {
        cancel: true,
        confirm: true
      }
    }).then(response => {
      if (response === true) {
        this.props
          .deleteUser(entity.id)
          .then(response => {
            swal("User deleted successfully", {
              icon: "success"
            });
            this.refreshTable();
          })
          .catch(error => {
            if (error.response)
              swal(error.response.data.message, {
                icon: "error"
              });
          });
      }
    });
  };

  refreshTable = () => {
    this.setState({
      users: [],
      tableSpinner: true
    });

    this.props
      .getUsers(this.state.query)
      .then(response => {
        this.setState({
          tableSpinner: false,
          meta: response.data.meta,
          users: response.data.data.map(user => {
            return {
              id: user.id,
              email: user.email,
              name: <span>{user.name}</span>,
              role: <InterestLabel label={user.role} />,
              permissions: user.permissions.map(permission => {
                return <InterestLabel key={permission} label={permission} />;
              }),
              options: (
                <DropdownMenu
                  button={
                    <img
                      src={ImageDots}
                      alt=""
                      className="Listing__view-icon"
                    />
                  }
                >
                  <DropdownLink
                    label="EDIT"
                    image={ImageDropdownEdit}
                    to={`/users/${user.id}/edit`}
                  />
                  <DropdownButton
                    entity={user}
                    label="DELETE"
                    image={ImageDropdownDelete}
                    onClick={this.onUserDelete}
                  />
                </DropdownMenu>
              )
            };
          })
        });
        this.props.updateQuantity(this.state.meta.total);
      })
      .catch(error => {
        alertError("Whooops! Something went wrong");
      });
  };

  noDataIndication = () => {
    if (this.state.tableSpinner === true) return <TableSpinner />;

    return <span>No information</span>;
  };

  render() {
    const pagination = {
      ...this.options,
      page: this.state.meta.current_page,
      paginationSize: this.state.meta.per_page,
      totalSize: this.state.meta.total
    };

    return (
      <div className="Listing">
        <div className="table-responsive" style={{ overflow: "hidden" }}>
          <BootstrapTable
            classes="table listing__table"
            remote={{
              pagination: true,
              sort: true,
              filter: true
            }}
            bordered={false}
            onTableChange={(type, fields) => null}
            defaultSorted={this.defaultSorted}
            keyField="id"
            data={this.state.users}
            columns={this.columns}
            noDataIndication={this.noDataIndication}
            pagination={paginationFactory(pagination)}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  {
    getUsers,
    deleteUser
  },
  null,
  {
    forwardRef: true
  }
)(UsersTable);
