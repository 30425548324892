import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { Container } from "reactstrap";
import AuthenticatedHeader from "../AuthenticatedHeader";
import Error404 from "./Error404";
import { setGlobalStyle } from "../../actions/common";
import UserList from "./UserList";
import PublisherList from "../Publishers/PublisherList";
import AppUserList from "./AppUserList";
import UserNew from "./UserNew";
import Profile from "./Profile";
import UserEdit from "./UserEdit";
import NewsNew from "./NewsNew";
import NewsList from "../News/NewsList";
import NewsEdit from "../News/Edit/NewsEdit";
import CategoriesList from "../Categories/CategoriesList";
import CategoryEdit from "../Categories/CategoryEdit";

const Panel = ({ globalStyle, login, history }) => {
  useEffect(() => {
    if (!login) {
      history.push("/login");
    }
  });

  return (
    <div
      id="wrapper"
      className={`wrapper AppContainer AppContainer--normal ${globalStyle}`}
    >
      <Container className="p-0 h-100" fluid>
        <AuthenticatedHeader />
        <Switch>
          <Route exact path="/" component={PublisherList} />
          <Route exact path="/users" component={UserList} />
          <Route exact path="/users/new" component={UserNew} />
          <Route exact path="/appusers" component={AppUserList} />
          <Route exact path="/news" component={NewsList} />
          <Route exact path="/news/new" component={NewsNew} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/categories" component={CategoriesList} />
          <Route exact path="/categories/:id/edit" component={CategoryEdit} />
          <Route exact path="/users/:id/edit" component={UserEdit} />
          <Route exact path="/news/:id/edit" component={NewsEdit} />
          <Route path="/" component={Error404} />
        </Switch>
      </Container>
    </div>
  );
};

const mapStateToProps = state => ({
  login: state.login,
  globalStyle: state.common.globalStyle
});

export default connect(
  mapStateToProps,
  {
    setGlobalStyle
  }
)(Panel);
