import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import combinedReducers from "../reducers";
import { logout } from "../actions/login";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["common", "newsSingle"]
};

const authInterceptor = ({ dispatch }) => next => action => {
  if (
    typeof action.response !== "undefined" &&
    action.response.status === 401
  ) {
    return dispatch(logout());
  } else {
    return next(action);
  }
};

const middlewares = [thunk, authInterceptor];

export default () => {
  const persistedReducer = persistReducer(persistConfig, combinedReducers);

  const devTools =
    process.env.NODE_ENV === "production"
      ? applyMiddleware(...middlewares)
      : composeEnhancers(applyMiddleware(...middlewares));

  const store = createStore(persistedReducer, devTools);

  const persistor = persistStore(store);
  return { store, persistor };
};
