import catchapp from "../apis/catchapp";
import authCatchapp from "../apis/catchapp";

const authenticatedCatchapp = jwt => {
  const header = `Bearer ${jwt}`;
  authCatchapp.defaults.headers.Authorization = header;

  return catchapp;
};

class UserAPIDefinition {
  login(email, password) {
    return catchapp.post("/api/admin/login", {
      email: email,
      password: password
    });
  }

  logout(jwt) {
    return catchapp.post("api/admin/logout");
  }

  forgotPassword(email) {
    return catchapp.post("api/admin/password/recovery", {
      email: email
    });
  }

  passwordRecovery(password, token) {
    return catchapp.post("api/admin/password/reset", {
      password,
      token
    });
  }

  editProfile(jwt, body) {
    return authenticatedCatchapp(jwt).post("api/admin/profile", body);
  }

  editAvatar(jwt, body) {
    return authenticatedCatchapp(jwt).post("api/admin/profile/avatar", body, {
      "Content-Type": "multipart/form-data"
    });
  }

  user(jwt, id) {
    return authenticatedCatchapp(jwt).get("api/admin/users/" + id);
  }

  userList(jwt, query) {
    return authenticatedCatchapp(jwt).get("api/admin/users", {
      params: {
        sort: query.sort,
        search: query.search,
        page: query.page
      }
    });
  }

  userDelete(jwt, id) {
    return authenticatedCatchapp(jwt).delete(`api/admin/users/${id}`);
  }

  userNew(jwt, body) {
    return authenticatedCatchapp(jwt).post("api/admin/users", body, {
      "Content-Type": "multipart/form-data"
    });
  }

  userEdit(jwt, id, body) {
    return authenticatedCatchapp(jwt).put("api/admin/users/" + id, body);
  }

  userEditAvatar(jwt, id, body) {
    return authenticatedCatchapp(jwt).post(
      `api/admin/users/${id}/avatar`,
      body,
      {
        "Content-Type": "multipart/form-data"
      }
    );
  }
}

class PublisherAPIDefinition {
  publisherList(jwt, page, filters) {
    let endpoint = `api/admin/api-news`;

    if (page) {
      endpoint = `${endpoint}?page=${page}`;
    }

    if (filters) {
      if (filters.search) {
        endpoint = `${endpoint}&search=${filters.search}`;
      }

      if (filters.sort) {
        endpoint = `${endpoint}&sort=${filters.sort}`;
      }

      if (filters.from && filters.to) {
        endpoint = `${endpoint}&from=${filters.from}&to=${filters.to}`;
      }
    }

    return authenticatedCatchapp(jwt).get(endpoint);
  }

  getPublisher(jwt, id) {
    return authenticatedCatchapp(jwt).get(`api/admin/api-news/${id}`);
  }

  refreshPublishers(jwt) {
    return authenticatedCatchapp(jwt).post("api/admin/api-news/refresh");
  }
}

class AppUserAPIDefinition {
  appUserList(jwt, query) {
    return authenticatedCatchapp(jwt).get("api/admin/appusers", {
      params: {
        sort: query.sort,
        search: query.search,
        page: query.page
      }
    });
  }
}

class NewsAPIDefinition {
  newsList(jwt, page, filters) {
    let endpoint = `api/admin/news`;

    if (page) {
      endpoint = `${endpoint}?page=${page}`;
    }

    if (filters) {
      if (filters.search) {
        endpoint = `${endpoint}&search=${filters.search}`;
      }

      if (filters.sort) {
        endpoint = `${endpoint}&sort=${filters.sort}`;
      }

      if (filters.from && filters.to) {
        endpoint = `${endpoint}&from=${filters.from}&to=${filters.to}`;
      }

      if (filters.categories && filters.categories.length > 0) {
        const parsedCategories = filters.categories
          .map(item => item.value)
          .reduce((prev, next) => `${prev},${next}`);
        endpoint = `${endpoint}&category_ids=${parsedCategories}`;
      }
    }

    return authenticatedCatchapp(jwt).get(endpoint);
  }

  newsSingle(jwt, id) {
    return authenticatedCatchapp(jwt).get(`/api/admin/news/${id}`);
  }

  deleteNews(jwt, id) {
    return authenticatedCatchapp(jwt).delete(`/api/admin/news/${id}`);
  }

  getAllGifs(jwt) {
    return authenticatedCatchapp(jwt).get("/api/admin/giphy/popular");
  }

  searchGif(jwt, query) {
    return authenticatedCatchapp(jwt).get(`/api/admin/giphy/${query}`);
  }

  saveDraftNews(jwt, news) {
    return authenticatedCatchapp(jwt).post(`/api/admin/news`, news);
  }

  updateDraftNews(jwt, id, news) {
    return authenticatedCatchapp(jwt).put(`/api/admin/news/${id}`, news);
  }

  publishNews(jwt, id) {
    return authenticatedCatchapp(jwt).post(`/api/admin/news/publish?ids=${id}`);
  }

  ageRanges(jwt) {
    return authenticatedCatchapp(jwt).get(`/api/age-ranges`);
  }
}

class CategoryAPIDefinition {
  categoriesList() {
    return catchapp.get("api/categories");
  }

  getCategory(jwt, id) {
    return authenticatedCatchapp(jwt).get(`/api/admin/categories/${id}`);
  }

  updateCategory(jwt, id, data) {
    return authenticatedCatchapp(jwt).put(`/api/admin/categories/${id}`, data);
  }
}

class AddressAPIDefinition {
  provincesList() {
    return catchapp.get("api/provinces");
  }

  locationsList() {
    return catchapp.get("api/locations");
  }
}

export const UserAPI = new UserAPIDefinition();
export const PublisherAPI = new PublisherAPIDefinition();
export const AppUserAPI = new AppUserAPIDefinition();
export const NewsAPI = new NewsAPIDefinition();
export const CategoryAPI = new CategoryAPIDefinition();
export const AddressAPI = new AddressAPIDefinition();
