import React from 'react';
import AppUsersTable from "../AppUsersTable";
import SearchBar from "../SearchBar";
import PageTransition from "../PageTransition";

class AppUserList extends React.Component {
    constructor(props) {
        super(props);

        this.appUsersTableRef = React.createRef();

        this.state = {
            quantity: 0
        };
    }

    updateQuantity = quantity => {
        this.setState({
            quantity: quantity
        })
    };

    onFormSubmit = term => {
        this.appUsersTableRef.current && this.appUsersTableRef.current.searchFor(term);
    };

    render() {
        return (
            <PageTransition>
                <div className="row ml-0 mr-0 mt-5 justify-content-center" id="">
                    <div className="col-12 col-sm-11 mt-5">
                        <div className="row ml-0 mr-0 my-4 justify-content-between">
                            <h1>APP USERS</h1>
                        </div>
                    </div>
                    <div className="col-12 col-sm-11">
                        <div className="row m-0">
                            <div className="col-12 col-sm-4 p-0">
                                <SearchBar
                                    onSubmit={this.onFormSubmit}
                                />
                                <p className='search-bar__result-search'>Se han encontrado {this.state.quantity} elementos</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-sm-11">
                        <div className="Listing">
                            <div className="table-responsive">
                                <AppUsersTable
                                    ref={this.appUsersTableRef}
                                    updateQuantity={this.updateQuantity}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </PageTransition>
        );
    }
}

export default AppUserList;
