import React from "react";

import { Row, Col, Label, Input, FormGroup } from "reactstrap";

const MessagesListContainer = ({ apiNews, addNewBody }) => (
  <>
    {apiNews.map(publisher => {
      return (
        <FormGroup key={publisher.id}>
          <Row form className="align-items-center">
            <Col xs="5">
              <small
                className="article__title create-news__titles-news"
                style={{ width: "100%", lineHeight: 1 }}
              >
                {publisher.title}
              </small>
            </Col>
            <Col xs="5">
              <Label for="body" className="create-news__titles-news">
                Messages link
              </Label>
            </Col>
            <Col xs="2" className="text-right">
              <span className="create-news__titles-news">
                {publisher.body[0] ? publisher.body[0].length : "0"}/140
              </span>
            </Col>
            <Col xs="12">
              <Input
                id="body"
                type="textarea"
                name="body"
                maxLength="140"
                value={publisher.body[0] || ""}
                onChange={e => addNewBody(publisher.id, e.target.value)}
              />
              <a
                href={publisher.link}
                target="_blank"
                className="create-news__link-news"
                rel="nofollow noopener noreferrer"
              >
                {publisher.portal}
              </a>
            </Col>
          </Row>
        </FormGroup>
      );
    })}
  </>
);

export default MessagesListContainer;
