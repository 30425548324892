import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import { setGlobalStyle } from "../../actions/common";
import PageTransition from "../PageTransition";

const Error404 = ({ setGlobalStyle }) => {
  useEffect(() => {
    setGlobalStyle("AppContainer--normal_grey");

    return () => setGlobalStyle("");
  });

  return (
    <div className="h-100-no-navbar d-flex align-items-center">
      <div className="w-100">
        <PageTransition>
          <Row className="m-0" id="content">
            <Col className="ForgotPasswordPage">
              <Container fluid>
                <Row className="ml-0 mr-0 justify-content-center">
                  <Col xs={12} sm={8}>
                    <h2 className="ForgotPasswordPage__msj text-center">
                      ERROR 404
                    </h2>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </PageTransition>
      </div>
    </div>
  );
};

export default connect(
  null,
  {
    setGlobalStyle
  }
)(Error404);
