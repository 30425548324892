import React from "react";

import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

const notFound = () => (
  <img
    src="https://github.githubassets.com/images/icons/emoji/octocat.png"
    alt="not found"
  />
);

const EmojiPicker = ({ getEmoji }) => {
  return (
    <Picker
      title="CatchApp Emoji"
      set="apple"
      sheetSize={32}
      onSelect={emoji => getEmoji(emoji)}
      style={{ position: "relative", border: "none", width: "100%" }}
      notFound={notFound}
    />
  );
};

export default EmojiPicker;
