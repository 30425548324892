import React from "react";
import DayPicker, { DateUtils } from "react-day-picker";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import moment from "moment";

import "react-day-picker/lib/style.css";

class RangePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      from: null,
      to: null,
      open: false
    };

    this.dayPickerRef = React.createRef();
  }
  handleClick = day => {
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);
  };

  onFormSubmit = event => {
    event.preventDefault();

    if (this.props.onSubmit)
      this.props.onSubmit(this.state.from, this.state.to);
  };

  onTodayButtonClick = async event => {
    await this.setState({
      from: new Date(),
      to: new Date()
    });

    if (this.props.onSubmit) {
      this.props.onSubmit(this.state.from, this.state.to);
    }
  };

  render() {
    const { from, to } = this.state;
    return (
      <div className="range-picker">
        <div className="dropdown">
          <button
            onClick={this.onTodayButtonClick}
            className="FormsGeneral-Button_blue_sm"
          >
            Today´s {moment().format("MMM Do")}
          </button>
          {/* <button
            className="FormsGeneral-Button_blue_sm"
            type="button"
            data-toggle="modal"
            data-target="#rangepicker-modal"
          >
            Range Datepicker
          </button> */}
          <div
            className="modal fade"
            id="rangepicker-modal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Filter by range</h5>
                </div>
                <div className="modal-body">
                  <DayPicker
                    ref={this.dayPickerRef}
                    className="Selectable"
                    onDayClick={this.handleClick}
                    selectedDays={[from, { from, to }]}
                    numberOfMonths={2}
                    modifiers={{
                      start: from,
                      end: to
                    }}
                  />
                  <br />
                </div>
                <div className="modal-footer">
                  <form onSubmit={this.onFormSubmit}>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                    <input
                      type="submit"
                      className="btn btn-success"
                      value="Filter"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Helmet>
          <style>{`
    .range-picker {
        display: inline-block;
    }
  .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }
  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`}</style>
        </Helmet>
      </div>
    );
  }
}

RangePicker.propTypes = {
  onChange: PropTypes.func
};

export default RangePicker;
