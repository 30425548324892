import React from "react";
import { FormGroup, Input, FormFeedback, Form, Label } from "reactstrap";
import { withRouter } from "react-router-dom";

import { Formik } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { updateCategory } from "../../actions/categories";

import SubmitButton from "../SubmitButton";
import TableSpinner from "../TableSpinner";
import PageTransition from "../PageTransition";

const CategoryForm = ({ category, updateCategory, id, history, loading }) => {
  if (loading) {
    return <TableSpinner />;
  }

  return (
    <PageTransition>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: category.name || "",
          image: ""
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required.")
        })}
        onSubmit={async (values, { setSubmitting }) => {
          let formatValues = {
            name: values.name
          };

          if (values.image) {
            formatValues = {
              ...formatValues,
              image: values.image
            };
          }

          const response = await updateCategory(id, formatValues);

          if (response === 422 || response === 404) {
            setSubmitting(false);
            return;
          }

          setSubmitting(false);
          history.push(`/categories`);
        }}
        render={({
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <Form onSubmit={handleSubmit}>
            <fieldset
              disabled={isSubmitting}
              aria-busy={isSubmitting}
              className="FormsGeneral"
            >
              <FormGroup className="mb-4">
                <div className="UploadImageWrapper">
                  <Label
                    for="image"
                    className="UploadImageLabel"
                    style={{
                      backgroundImage: `url(${
                        values.image ? values.image : category.image
                      })`
                    }}
                  >
                    <span>Change Image</span>
                  </Label>
                  <Input
                    id="image"
                    type="file"
                    style={{ display: "none" }}
                    onChange={e => {
                      e.preventDefault();
                      let file = e.target.files[0];
                      let previewReader = new FileReader();

                      if (!file) {
                        return;
                      }

                      previewReader.onloadend = () => {
                        setFieldValue("image", previewReader.result);
                      };

                      previewReader.readAsDataURL(file);
                    }}
                  />
                </div>
              </FormGroup>
              <FormGroup>
                <Input
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="FormsGeneral-Inputs"
                  placeholder="NAME*"
                />
                {errors.name && touched.name && (
                  <FormFeedback className="d-block">{errors.name}</FormFeedback>
                )}
              </FormGroup>
              <FormGroup>
                <SubmitButton
                  className="badge badge-pill badge-primary FormsGeneral-Button"
                  disabled={isSubmitting}
                >
                  Save Changes
                </SubmitButton>
              </FormGroup>
            </fieldset>
          </Form>
        )}
      />
    </PageTransition>
  );
};

const mapStateToProps = state => ({
  loading: state.common.categories.loading,
  category: state.common.categories.category
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      updateCategory
    }
  )(CategoryForm)
);
