import { NewsAPI } from "../middlewares/api";
import { alertError } from "../helpers/alerts";
import { format } from "date-fns";

export const getNewsList = (page = 1, filters) => async (
  dispatch,
  getState
) => {
  dispatch({ type: "GET_NEWS_LOADING" });

  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await NewsAPI.newsList(authToken, page, filters);
      dispatch({
        type: "GET_NEWS_SUCCESS",
        payload: {
          newslist: response.data.data,
          links: {
            first: response.data.links.first,
            last: response.data.links.last,
            next: response.data.links.next,
            prev: response.data.links.prev
          },
          meta: {
            currentPage: response.data.meta.current_page,
            from: response.data.meta.from,
            last_page: response.data.meta.last_page,
            per_page: response.data.meta.per_page,
            to: response.data.meta.to,
            total: response.data.meta.total
          }
        }
      });
    } catch (error) {
      dispatch({ type: "GET_NEWS_ERROR" });
      alertError("Something bad happened.");
    }
  }
};

export const setCategories = list => async dispatch => {
  dispatch({
    type: "SET_CATEGORIES",
    payload: {
      value: list
    }
  });
};

export const setNewsTitle = title => ({
  type: "SET_TITLE",
  payload: {
    title
  }
});

export const setNewsPriority = value => {
  const intValue = parseInt(value);

  return {
    type: "SET_PRIORITY",
    payload: {
      value: intValue
    }
  };
};

export const setNewsBody = text => ({
  type: "SET_BODY",
  payload: {
    value: text
  }
});

export const getGifs = () => async (dispatch, getState) => {
  const authToken = getState().login.authToken;

  dispatch({ type: "GET_GIFS_LOADING" });

  const response = await NewsAPI.getAllGifs(authToken);

  dispatch({
    type: "GET_GIF_SUCCESS",
    payload: {
      gifs: response.data.giphys
    }
  });
};

export const setGif = gif => dispatch => {
  dispatch({ type: "REMOVE_GIF" });

  dispatch({
    type: "SET_GIF",
    payload: {
      value: gif
    }
  });
};

export const removeGif = () => ({
  type: "REMOVE_GIF"
});

export const searchGifs = query => async (dispatch, getState) => {
  const authToken = getState().login.authToken;

  dispatch({ type: "GET_GIFS_LOADING" });

  const response = await NewsAPI.searchGif(authToken, query);

  dispatch({
    type: "SEARCH_GIF_SUCCESS",
    payload: {
      gifs: response.data.giphys
    }
  });
};

export const setNewsYesAnswer = text => ({
  type: "SET_YES_TEXT",
  payload: {
    value: text
  }
});

export const setNewsNoAnswer = text => ({
  type: "SET_NO_TEXT",
  payload: {
    value: text
  }
});

export const setYesEmoji = ({ unified }) => dispatch => {
  dispatch({
    type: "SET_YES_EMOJI",
    payload: {
      unified
    }
  });
};

export const removeYesEmoji = () => ({
  type: "REMOVE_YES_EMOJI"
});

export const setNoEmoji = ({ unified }) => dispatch => {
  dispatch({
    type: "SET_NO_EMOJI",
    payload: {
      unified
    }
  });
};

export const removeNoEmoji = () => ({
  type: "REMOVE_NO_EMOJI"
});

export const setNewsFrequency = value => ({
  type: "SET_FREQUENCY",
  payload: {
    value
  }
});

export const setNewsGender = list => async dispatch => {
  const genderList = list.map(item => item.value);

  dispatch({
    type: "SET_GENDER",
    payload: {
      value: genderList
    }
  });
};

export const addNewBody = (id, text) => ({
  type: "ADD_BODY_TEXT",
  payload: {
    id,
    body: [text]
  }
});

export const setStartAt = date => dispatch => {
  const formatDate = format(new Date(date), "YYYY-MM-DD hh:mm:ss", {
    awareOfUnicodeTokens: true
  });

  dispatch({
    type: "SET_START_AT",
    payload: {
      value: formatDate
    }
  });
};

export const setEndsAt = date => dispatch => {
  // const formatDate = moment(date).format("YYYY-MM-DD hh:mm:ss");
  const formatDate = format(new Date(date), "YYYY-MM-DD hh:mm:ss", {
    awareOfUnicodeTokens: true
  });

  dispatch({
    type: "SET_ENDS_AT",
    payload: {
      value: formatDate
    }
  });
};

export const deletePublishersFromTableNews = id => dispatch => {
  dispatch({
    type: "DELETE_PUBLISHERS_FROM_TABLE",
    payload: {
      id
    }
  });
};

export const setAgeRange = value => async dispatch => {
  const idArr = value.map(item => item.value);

  dispatch({
    type: "SET_AGE_RANGES",
    payload: {
      value: idArr
    }
  });
};
