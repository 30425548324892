import React, { useState, Fragment } from "react";
import styled from "styled-components";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  CardColumns,
  Card,
  Input
} from "reactstrap";
import _ from "lodash";
import AddRemoveGifEmoji from "./AddRemoveGifEmoji";

import { connect } from "react-redux";
import { getGifs, searchGifs } from "../actions/news";

import TableSpinner from "./TableSpinner";

const PickerWrapper = styled(DropdownMenu)`
  min-width: 380px;
  border-top: 2px solid #f87829;
  margin-top: 2em;
  height: 300px;
  overflow-y: scroll;
`;

const GifItem = styled.div`
  cursor: pointer;
`;

const GifLoadingWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const AddGifBtn = styled(DropdownToggle)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem transparent !important;
  }

  &:active {
    background-color: transparent !important;
    border-color: transparent !important;
  }
`;

const GifPicker = ({
  image,
  text,
  getGifs,
  loading = true,
  gifs,
  searchGifs,
  setGifSubmit
}) => {
  const [open, setOpen] = useState(false);
  const [wasOpen, setWasOpen] = useState(false);

  const handleOpen = async () => {
    setOpen(!open);
    setWasOpen(false);

    if (!wasOpen) {
      await getGifs();
      setWasOpen(true);
    }
  };

  const handlePick = gif => {
    setGifSubmit(gif);
    setOpen(!open);
    setWasOpen(false);
  };

  const searchGifAwait = async event => {
    const { value } = event.target;
    await searchGifs(value);
  };

  // With this I call the endpoint every 1000ms after use onChange Event.
  const delayedCallback = _.debounce(searchGifAwait, 1000);

  const onChange = event => {
    // If you want to access the event properties in an asynchronous way
    event.persist();
    delayedCallback(event);
  };

  return (
    <ButtonDropdown isOpen={open} toggle={handleOpen}>
      <AddGifBtn className="add-remove-buttons mx-2 p-2">
        <AddRemoveGifEmoji image={image} text={text} />
      </AddGifBtn>
      <PickerWrapper className="p-3">
        <Fragment>
          <Row noGutters className="mb-2">
            <Col>
              <Input type="text" placeholder="Search gif" onChange={onChange} />
            </Col>
          </Row>
          {loading ? (
            <GifLoadingWrapper>
              <TableSpinner />
            </GifLoadingWrapper>
          ) : (
            <Row noGutters>
              <Col>
                <CardColumns>
                  {gifs.map(gif => (
                    <Card key={gif.id} className="px-1 py-1">
                      <GifItem
                        onClick={() =>
                          handlePick(
                            `https://media.giphy.com/media/${gif.id}/giphy.gif`
                          )
                        }
                      >
                        <picture>
                          <source
                            type="image/webp"
                            srcSet={gif.webp}
                            className="w-100"
                          />
                          <img
                            src={gif.preview}
                            alt={`${gif.id} GIF`}
                            className="w-100"
                          />
                        </picture>
                      </GifItem>
                    </Card>
                  ))}
                </CardColumns>
              </Col>
            </Row>
          )}
        </Fragment>
      </PickerWrapper>
    </ButtonDropdown>
  );
};

const mapStateToProps = state => ({
  loading: state.news.gifList.loading,
  gifs: state.news.gifList.data
});

export default connect(
  mapStateToProps,
  {
    getGifs,
    searchGifs
  }
)(GifPicker);
