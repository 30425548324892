import React from "react";
import { withRouter } from "react-router-dom";
import { Input, Form, FormGroup, FormText, FormFeedback } from "reactstrap";

import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { connect } from "react-redux";
import { newUser } from "../actions/users";

import SubmitButton from "./SubmitButton";
import Checkbox from "./Checkbox";

const permissions = ["Manage News", "Manage Ads", "Manage Users"];

const FormUserNew = ({ newUser, history }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        role: "",
        permissions: [],
        password: ""
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required."),
        email: Yup.string()
          .email()
          .required("Required."),
        role: Yup.string().required("Required."),
        password: Yup.string().required("Required.")
      })}
      onSubmit={async (values, { setSubmitting }) => {
        let newUserData = {
          ...values,
          permissions: values.role === "admin" ? [] : values.permissions
        };

        const response = await newUser(newUserData);

        if (response === 200) {
          history.push("/users");
        }

        setSubmitting(false);
      }}
      render={({
        values,
        touched,
        errors,
        isSubmitting,
        handleChange,
        handleBlur,
        handleSubmit
      }) => (
        <Form onSubmit={handleSubmit}>
          <fieldset
            disabled={isSubmitting}
            aria-busy={isSubmitting}
            className="FormsGeneral"
          >
            <FormGroup>
              <Input
                type="text"
                name="name"
                id="name"
                placeholder="USERNAME NAME* "
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                className="FormsGeneral-Inputs"
              />
              {errors.name && touched.name && (
                <FormFeedback className="d-block">{errors.name}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                name="email"
                type="email"
                id="email"
                placeholder="EMAIL*"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className="FormsGeneral-Inputs"
              />
              {errors.email && touched.email && (
                <FormFeedback className="d-block">{errors.email}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                name="password"
                id="password"
                placeholder="PASSWORD* "
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                className="FormsGeneral-Inputs"
              />
              {errors.password && touched.password && (
                <FormFeedback className="d-block">
                  {errors.password}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="select"
                name="role"
                value={values.role}
                onChange={handleChange}
                onBlur={handleBlur}
                className="FormsGeneral-Select FormsGeneral-Select--Line"
              >
                <option value="" disabled={true}>
                  Access Type
                </option>
                <option value="admin">Admin</option>
                <option value="editor">Editor</option>
              </Input>
              {errors.role && touched.role && (
                <FormFeedback className="d-block">{errors.role}</FormFeedback>
              )}
            </FormGroup>
            {values.role === "editor" && (
              <FieldArray
                name="permissions"
                render={arrayHelpers => (
                  <div className="mt-4">
                    {permissions.map(item => (
                      <div key={item}>
                        <Checkbox
                          label={item}
                          value={item}
                          checked={values.permissions.includes(
                            item.toLocaleLowerCase()
                          )}
                          onChange={e => {
                            if (e.target.checked) {
                              arrayHelpers.push(item.toLocaleLowerCase());
                            } else {
                              const idx = values.permissions.indexOf(
                                item.toLocaleLowerCase()
                              );
                              arrayHelpers.remove(idx);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              />
            )}
            <FormGroup>
              <FormText color="muted">Required Fields *</FormText>
            </FormGroup>
            <FormGroup>
              <SubmitButton
                className="badge badge-pill badge-primary FormsGeneral-Button"
                disabled={isSubmitting}
              >
                SAVE
              </SubmitButton>
            </FormGroup>
          </fieldset>
        </Form>
      )}
    />
  );
};

export default withRouter(
  connect(
    null,
    {
      newUser
    }
  )(FormUserNew)
);
