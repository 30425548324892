import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import GifPreview from "./GifPreview";

const PortalUrl = styled.a`
  font-size: 12px;
  color: #a6a9ad;
`;

const PhoneBody = styled.div`
  min-height: calc(650px - 109px);
  height: calc(650px - 109px);
  overflow-y: scroll;
`;

const PhoneFooter = styled.div``;

const LivePreview = ({
  catchappMessage,
  gif,
  apiNews,
  yesEmoji,
  noEmoji,
  yesText,
  noText
}) => {
  return (
    <div className="p-4">
      <Row className="my-0">
        <Col className="my-4">
          <span className="create-news__titles-news">Preview</span>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="create-news__preview-container"
            style={{ minHeight: "650px" }}
          >
            <PhoneBody>
              <Row className="mr-0 ml-0 mt-5">
                {catchappMessage.length > 0 && (
                  <Col xs="12" className="my-3">
                    <div className="talk-bubble tri-right round btm-left">
                      <div className="talktext">
                        <p>{catchappMessage}</p>
                      </div>
                    </div>
                  </Col>
                )}

                {gif && <GifPreview gif={gif} />}

                {apiNews.map(item => (
                  <Col xs="12" className="my-3" key={item.id}>
                    {item.body[0] && item.body[0].length > 0 && (
                      <>
                        <div>
                          <div className="talk-bubble tri-right round btm-left">
                            <div className="talktext">
                              <p>{item.body[0]}</p>
                            </div>
                          </div>
                        </div>
                        <small>
                          <PortalUrl
                            href={item.link}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                          >
                            {item.portal}
                          </PortalUrl>
                        </small>
                      </>
                    )}
                  </Col>
                ))}
              </Row>
            </PhoneBody>

            <PhoneFooter>
              <Col xs="12" className="bg-preview__footer">
                <Row className="m-0 create-news__titles-news create-news__titles-preview">
                  <Col xs="4" className="p-0">
                    ANSWER
                  </Col>
                  <Col className="text-right">
                    <Row className="m-0">
                      <Col xs="12" className="p-0">
                        <Row className="mb-2">
                          <Col xs="2">Y:</Col>
                          <Col>
                            <div className="talk-bubble talk-bubble_answer tri-right round btm-right">
                              <div className="talktext talktext_answer">
                                <p>
                                  {yesText}{" "}
                                  {yesEmoji.length > 0 &&
                                    String.fromCodePoint(yesEmoji)}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="2">N:</Col>
                          <Col>
                            <div className="talk-bubble talk-bubble_answer tri-right round btm-right">
                              <div className="talktext talktext_answer">
                                <p>
                                  {noText}{" "}
                                  {noEmoji.length > 0 &&
                                    String.fromCodePoint(noEmoji)}
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </PhoneFooter>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LivePreview;
