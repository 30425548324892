import { CategoryAPI } from "../middlewares/api";
import { alertError, alertSuccess } from "../helpers/alerts";
import ErrorReducer from "../helpers/ErrorReducer";

export const getCategory = id => async (dispatch, getState) => {
  dispatch({ type: "LOADING_CATEGORIES" });

  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await CategoryAPI.getCategory(authToken, id);

      dispatch({
        type: "GET_SINGLE_CATEGORY_SUCCESS",
        payload: {
          category: {
            name: response.data.category.name,
            image: response.data.category.image
          }
        }
      });

      return response.status;
    } catch (error) {
      dispatch({ type: "GET_SINGLE_CATEGORY_FAILURE" });

      if (error.response.status === 404) {
        alertError("Sorry that category do not exists");
      } else {
        alertError(`Sorry something bad happened, try again.`);
      }

      return error.response.status;
    }
  }
};

export const updateCategory = (id, data) => async (dispatch, getState) => {
  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await CategoryAPI.updateCategory(authToken, id, data);
      if (response.status === 200) {
        alertSuccess("The category was succefully edited");
      }
      return response.status;
    } catch (error) {
      if (error.response.status === 404) {
        alertError("Sorry that category do not exists");
      } else if (error.response.status === 422) {
        const errorList = ErrorReducer(error.response.data.errors);
        const errorStringify = errorList.reduce(
          (prev, next) => `${prev}\n${next}`
        );
        alertError(errorStringify);
      } else {
        alertError("Sorry something bad happened, try again.");
      }

      return error.response.status;
    }
  }
};
