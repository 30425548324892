import React, { Fragment, Component } from "react";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, Row, Col, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getNewsList } from "../../actions/news";
import { publishNews, getCategories } from "../../actions/common";

import NewsTable from "./NewsTable";
import RangePicker from "../RangePicker";
import SearchBar from "../SearchBar";
import FilterList from "../FilterList";
import PageTransition from "../PageTransition";
import TableSpinner from "../TableSpinner";
import CategoryFilter from "../CategoryFilter";

class NewsList extends Component {
  state = {
    modal: false,
    filters: {
      today: null,
      sort: "-created_at",
      search: null,
      from: null,
      to: null,
      categories: []
    },
    newsToPublish: [],
    submitting: false
  };

  async componentDidMount() {
    const { getNewsList, getCategories } = this.props;
    const { filters } = this.state;

    await Promise.all([getNewsList(1, filters), getCategories()]);
  }

  toggleLoading = () => {
    this.setState({ submitting: !this.state.submitting });
  };

  handleSubmit = async (page, filters) => {
    const { getNewsList } = this.props;
    await getNewsList(page, filters);
  };

  setNewsToPublish = news => {
    this.setState({
      ...this.state,
      newsToPublish: [...this.state.newsToPublish, news]
    });
  };

  deleteNewsToPublish = newsId => {
    this.setState({
      ...this.state,
      newsToPublish: this.state.newsToPublish.filter(news => news.id !== newsId)
    });
  };

  handleSearch = term => {
    if (term.length > 0) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: term,
            title: term
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: null,
            title: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }
  };

  onRangePickerSubmit = async (from, to) => {
    const dateFrom = moment(from).format("YYYY-MM-DD");
    const dateTo = moment(to).format("YYYY-MM-DD");

    if (dateFrom !== dateTo) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            from: dateFrom,
            to: dateTo,
            today: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            from: dateFrom,
            to: dateTo,
            today: "today"
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }
  };

  onCategoryFilterChange = async categories => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          categories: categories
        }
      },
      () => this.handleSubmit(1, this.state.filters)
    );
  };

  handleSortByField = async (field, order) => {
    let sort;
    if (order === "desc") {
      sort = `-${field}`;
    } else {
      sort = field;
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          sort
        }
      },
      () => this.handleSubmit(1, this.state.filters)
    );
  };

  handlePaginator = async page => {
    this.handleSubmit(page, this.state.filters);
  };

  handleDeleteFilter = async filterName => {
    if (filterName === "sort") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            sort: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }

    if (filterName === "today") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            today: null,
            from: null,
            to: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }

    if (filterName === "search") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }

    if (filterName === "categories") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            categories: []
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }
  };

  handleModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleModalConfirm = async () => {
    const { publishNews, getNewsList } = this.props;
    const { newsToPublish, filters } = this.state;
    this.toggleLoading();
    const news = newsToPublish
      .map(item => item.id)
      .reduce((prev, next) => `${prev},${next}`);

    const response = await publishNews(news);

    this.setState({
      modal: false
    });

    if (response && response.status === 204) {
      await getNewsList(1, filters);
      this.deleteNewsToPublishOnDelete();
    }

    this.toggleLoading();
  };

  deleteNewsToPublishOnDelete = () => {
    this.setState({
      newsToPublish: []
    });
  };

  render() {
    const { newsToPublish, submitting, modal } = this.state;
    const { loading, totalNews, newsList } = this.props;

    return (
      <Fragment>
        <PageTransition>
          <Row className="ml-0 mr-0 justify-content-center">
            <Col xs={12} sm={11} className="mt-5">
              <Row className="ml-0 mr-0 my-4 d-flex justify-content-between align-items-center">
                <h1 className="mb-0">ALL NEWS</h1>
                <RangePicker onSubmit={this.onRangePickerSubmit} />
              </Row>
            </Col>

            <Col xs={12} sm={11}>
              <Row>
                <Col xs={12} sm={4}>
                  <div className="d-flex">
                    <SearchBar onSubmit={this.handleSearch} />
                  </div>
                </Col>
                <Col xs={12} sm={4}>
                  <CategoryFilter setCategories={this.onCategoryFilterChange} />
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={11}>
                  <span className="d-block mt-4 search-bar__result-search">
                    Se han encontrado{" "}
                    {loading ? (
                      <span>
                        <Spinner size="sm" color="secondary" />
                      </span>
                    ) : (
                      totalNews || "0"
                    )}{" "}
                    elementos
                  </span>
                  <FilterList
                    filters={this.state.filters}
                    handleDeleteFilter={this.handleDeleteFilter}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={11}>
              <div className="position-relative table-min-height">
                {loading && (
                  <div className="loading-table">
                    <TableSpinner />
                  </div>
                )}

                <NewsTable
                  newsList={newsList}
                  loading={loading}
                  setNewsToPublish={this.setNewsToPublish}
                  deleteNewsToPublish={this.deleteNewsToPublish}
                  handlePaginator={this.handlePaginator}
                  handleSortByField={this.handleSortByField}
                  deleteNewsToPublishOnDelete={this.deleteNewsToPublishOnDelete}
                />
              </div>
            </Col>

            <Col xs={12} className="my-5">
              {newsToPublish.length > 0 && (
                <button
                  onClick={this.handleModalToggle}
                  type="submit"
                  className="badge badge-pill badge-primary FormsGeneral-Button"
                >
                  PUBLISH
                </button>
              )}
            </Col>
          </Row>
        </PageTransition>

        <Modal isOpen={modal} centered className="confirmModal">
          <ModalBody className="p-5">
            <h2 className="mb-3">
              Are you sure want to publish the following news?
            </h2>
            <ol className="m-0 pl-5">
              {newsToPublish.map(news => (
                <li key={news.id}>
                  <p className="text-muted">{news.title}</p>
                </li>
              ))}
            </ol>
          </ModalBody>

          <ModalFooter className="no-border">
            {!submitting && (
              <button
                onClick={this.handleModalToggle}
                className="swal-button swal-button--cancel"
              >
                Cancel
              </button>
            )}

            <button
              onClick={this.handleModalConfirm}
              className="swal-button swal-button--confirm swal-button--danger"
              disabled={submitting ? true : false}
            >
              {submitting ? <Spinner size="sm" color="light" /> : "Ok"}
            </button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  newsList: state.news.newslist,
  totalNews: state.news.meta.total,
  loading: state.news.loading
});

export default connect(
  mapStateToProps,
  {
    getNewsList,
    publishNews,
    getCategories
  }
)(NewsList);
