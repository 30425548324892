import React, { useState } from "react";
import { alertSuccess } from "../../helpers/alerts";
import _ from "lodash";
import { Form, Input } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { passwordRecovery } from "../../actions/login";

import SubmitButton from "../SubmitButton";

const FormPasswordRecovery = ({ token, passwordRecovery, history }) => {
  const [fields, setFields] = useState({
    password: "",
    passwordConfirm: ""
  });

  const [fieldErrors, setFieldErrors] = useState({
    password: null,
    confirmPassword: null,
    token: null
  });

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const onFormSubmit = async e => {
    e.preventDefault();
    const { password, passwordConfirm } = fields;
    setLoading(true);

    if (password !== passwordConfirm) {
      setError("Your password and confirmation password do not match.");
      setLoading(false);
      return;
    }

    try {
      await passwordRecovery(password, token);

      history.push("/login");

      alertSuccess("Your password has been successfully changed");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        _.map(error.response.data.errors, (value, key) => {
          setFieldErrors({
            ...fieldErrors,
            [key]: value,
            confirmPassword: !fields.confirmPassword
              ? "The confirmation password is required."
              : null
          });

          setError(error.response.data.message);
        });
      } else if (error.response && error.response.status === 400) {
        setError(error.response.data.message);
      } else {
        setError(error.message);
      }

      setLoading(false);
    }
  };

  return (
    <Form className="FormsGeneral w-100" onSubmit={onFormSubmit}>
      <Input
        name="password"
        type="password"
        className="form-control FormsGeneral-Inputs"
        placeholder="PASSWORD *"
        value={fields.password}
        onChange={e =>
          setFields({ ...fields, [e.target.name]: e.target.value })
        }
      />
      {fieldErrors.password && (
        <span className="help-block text-danger input-errors">
          {fieldErrors.password}
        </span>
      )}
      <Input
        name="passwordConfirm"
        type="password"
        className="form-control FormsGeneral-Inputs"
        placeholder="REPEAT PASSWORD *"
        value={fields.passwordConfirm}
        onChange={e =>
          setFields({ ...fields, [e.target.name]: e.target.value })
        }
      />
      {fieldErrors.confirmPassword && (
        <span className="help-block text-danger input-errors">
          {fieldErrors.confirmPassword}
        </span>
      )}
      <div className="form-forgot-pass__bottom">
        <span className="FormsGeneral-Required">* Required Field </span>
        {error && <p className="text-danger mt-4">{error}</p>}

        <div className="mt-3">
          <SubmitButton
            className="badge badge-pill badge-primary FormsGeneral-Button"
            disabled={loading}
          >
            DONE
          </SubmitButton>
        </div>
      </div>
    </Form>
  );
};

export default withRouter(
  connect(
    null,
    {
      passwordRecovery
    }
  )(FormPasswordRecovery)
);
