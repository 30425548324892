import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Form, Input } from "reactstrap";
import _ from "lodash";

import { connect } from "react-redux";
import { tryLogin } from "../../actions/login";

import SubmitButton from "../SubmitButton";

const FormLogin = ({ tryLogin, history }) => {
  const [fields, setFields] = useState({
    email: "",
    password: ""
  });
  const [errors, setErrors] = useState({
    email: null,
    password: null
  });
  const [messageError, setMessageError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onFormSubmit = async e => {
    e.preventDefault();
    const { email, password } = fields;
    setLoading(true);

    try {
      await tryLogin(email, password);

      history.push("/");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let errorsObj = {};
        _.map(error.response.data.errors, (value, key) => {
          errorsObj = {
            ...errorsObj,
            [key]: value[0]
          };
        });

        setErrors({ ...errorsObj });
      } else {
        setMessageError(error.message);
      }

      setLoading(false);
    }
  };

  return (
    <Form onSubmit={onFormSubmit} className="FormsGeneral text-center">
      <Input
        name="email"
        type="text"
        className="form-control FormsGeneral-Inputs FormsGeneral-Inputs_blue"
        placeholder="Mail*"
        value={fields.email}
        onChange={e =>
          setFields({ ...fields, [e.target.name]: e.target.value })
        }
      />
      {errors.email && (
        <span className="help-block text-danger input-errors">
          {errors.email}
        </span>
      )}

      <Input
        name="password"
        type="password"
        className="form-control FormsGeneral-Inputs FormsGeneral-Inputs_blue"
        placeholder="Password*"
        value={fields.password}
        onChange={e =>
          setFields({ ...fields, [e.target.name]: e.target.value })
        }
      />
      {errors.password && (
        <span className="help-block text-danger input-errors">
          {errors.password}
        </span>
      )}

      {messageError && (
        <p className="FormsGeneral-Required text-danger">{messageError}</p>
      )}

      <div className="FormsGeneral-ButtonSetContainer">
        <SubmitButton
          className="badge badge-pill badge-primary FormsGeneral-Button FormsGeneral-Button_blue w-100"
          disabled={loading}
        >
          Login
        </SubmitButton>
      </div>
      <Link to="/login/forgot-password" className="LoginPage-link">
        Forgot your password?
      </Link>
    </Form>
  );
};

export default withRouter(
  connect(
    null,
    {
      tryLogin
    }
  )(FormLogin)
);
