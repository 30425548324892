import React, { Component } from "react";
import { Row, Col, Spinner } from "reactstrap";

import { connect } from "react-redux";
import { getCategories } from "../../actions/common";

import PageTransition from "../PageTransition";
import TableSpinner from "../TableSpinner";
import CategoriesTable from "./CategoriesTable";

class CategoriesList extends Component {
  async componentDidMount() {
    const { getCategories } = this.props;
    await getCategories(true);
  }

  render() {
    const { loading, categories } = this.props;

    return (
      <PageTransition>
        <Row className="ml-0 mr-0 justify-content-center">
          <Col xs={12} sm={11} className="mt-5">
            <Row className="ml-0 mr-0 my-4 d-flex justify-content-between align-items-center">
              <h1 className="mb-0">ALL CATEGORIES</h1>
            </Row>
          </Col>

          <Col xs={12} sm={11}>
            <span className="d-block m-0 search-bar__result-search">
              Se han encontrado{" "}
              {loading ? (
                <span>
                  <Spinner size="sm" color="secondary" />
                </span>
              ) : (
                <span>{categories.length}</span>
              )}{" "}
              elementos
            </span>
          </Col>

          <Col xs={12} sm={11}>
            <div className="position-relative table-min-height">
              {loading && (
                <div className="loading-table">
                  <TableSpinner />
                </div>
              )}

              {categories.length && !loading > 0 && (
                <CategoriesTable categories={categories} />
              )}
            </div>
          </Col>
        </Row>
      </PageTransition>
    );
  }
}

const mapStateToprops = state => ({
  loading: state.common.categories.loading,
  categories: state.common.categories.data
});

export default connect(
  mapStateToprops,
  {
    getCategories
  }
)(CategoriesList);
