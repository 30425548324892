import React, { useState, useEffect } from "react";

const Checkbox = ({ onChange, checked, label }) => {
  const [isCheked, setChecked] = useState(false);

  const id = label.split(" ").join("_");
  const idx = `checkbox_${id}_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

  useEffect(() => {
    if (checked) {
      setChecked(true);
    }
  }, []);

  const handleChange = e => {
    onChange(e);

    if (e.target.checked) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  return (
    <div className="contaniner__access-type-items">
      <div className="checkbox-grey  d-inline-block">
        <input
          checked={isCheked}
          onChange={handleChange}
          type="checkbox"
          value={isCheked}
          id={idx}
          name="check"
        />
        <label htmlFor={idx} />
      </div>
      <span className="checkbox-grey__label">{label}</span>
    </div>
  );
};

export default Checkbox;
