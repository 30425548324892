import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import { Container } from "reactstrap";

import UnauthenticatedHeader from "./UnauthenticatedHeader";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordSuccess from "./ForgotPasswordSuccess";
import PasswordRecovery from "./PasswordRecovery";
import Error404 from "../pages/Error404";

const UnauthenticatedPanel = ({ globalStyle }) => (
  <div
    id="wrapper"
    className={`wrapper AppContainer AppContainer--normal ${globalStyle}`}
  >
    <Container className="p-0 h-100" fluid>
      <UnauthenticatedHeader />
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/login/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/login/forgot-password/ok"
          component={ForgotPasswordSuccess}
        />
        <Route
          exact
          path="/login/password-recovery/:token"
          component={PasswordRecovery}
        />

        <Route path="/" component={Error404} />
      </Switch>
    </Container>
  </div>
);

const mapStateToProps = state => ({
  globalStyle: state.common.globalStyle
});

export default connect(mapStateToProps)(UnauthenticatedPanel);
