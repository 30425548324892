import React, { useState, Fragment } from "react";
import {
  Row,
  Container,
  Nav,
  NavItem,
  Navbar,
  NavbarToggler,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { logout } from "../actions/login";
import { resetEditing, resetPublishersAll } from "../actions/common";
import { getPublishers } from "../actions/publishers";

import DropdownLogoutImage from "../images-eve/logout-popover.svg";
import ArrowBackImage from "../images-eve/arrow-white.svg";
import SeparationLine from "../images/separation-line-menu.svg";
import LogoImage from "../images/header-logo.svg";
import ArrowDown from "../images-eve/icon-down-arrow.svg";
import EditImage from "../images-eve/edit-grey.svg";

const AuthenticatedHeader = ({
  history,
  logout,
  login,
  match,
  resetEditing,
  permit,
  getPublishers,
  resetPublishersAll
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Row className="m-0">
        <Navbar color="dark" dark expand="lg" className="w-100 header-mobile">
          <Link to="/">
            <img
              className="header-mobile__brand-img"
              src={LogoImage}
              alt="CatchApp"
            />
          </Link>
          <NavbarToggler onClick={() => setOpen(!open)} />
          <Collapse isOpen={open} navbar>
            <Nav className="mt-4" navbar>
              <NavItem>
                <Link
                  to="/profile"
                  className="nav-link Header-Container__nav-links"
                >
                  <img
                    className="rounded-circle Header-Container__user-image mr-3"
                    src={login ? login.user.avatar : ""}
                    alt="My account"
                  />
                  My account
                </Link>
              </NavItem>
              <NavItem>
                <button
                  className="nav-item nav-link Header-Container__nav-links"
                  onClick={() => {
                    history.push("/");
                    resetEditing();
                    resetPublishersAll();
                    setOpen(!open);
                  }}
                >
                  publishers
                </button>
              </NavItem>
              <NavItem>
                <button
                  className="nav-item nav-link Header-Container__nav-links"
                  onClick={() => {
                    resetEditing();
                    resetPublishersAll();
                    history.push("/news");
                    setOpen(!open);
                  }}
                >
                  news
                </button>
              </NavItem>
              <NavItem>
                <Link
                  to="/users"
                  className="nav-item nav-link Header-Container__nav-links"
                >
                  admin users
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  to="/appusers"
                  className="nav-item nav-link Header-Container__nav-links"
                >
                  app users
                </Link>
              </NavItem>

              <NavItem>
                <Link
                  to="/categories"
                  className="nav-item nav-link Header-Container__nav-links"
                >
                  categories
                </Link>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  className="badge-pill my-4 ListingActionButton dropdown-menu-toggle"
                >
                  CREATE
                  <img
                    src={ArrowDown}
                    alt="create"
                    className="ListingAction-Icon"
                  />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-wrapper">
                  <DropdownItem
                    className="dropdown-item__general text-white"
                    onClick={() => {
                      resetEditing();
                      resetPublishersAll();
                      history.push("/news/new");
                      setOpen(!open);
                    }}
                  >
                    NEWS
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <DropdownItem
                  className="nav-item nav-link Header-Container__nav-links"
                  onClick={() => {
                    logout();
                    setOpen(!open);
                  }}
                >
                  <img
                    src={DropdownLogoutImage}
                    className="icons-popover"
                    alt="profile button"
                  />
                  LOGOUT
                </DropdownItem>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Row>

      <Row className="m-0">
        <Navbar
          color="dark"
          dark
          expand="lg"
          className="w-100 pl-0 header-desktop"
        >
          <Container className="header-desktop__Container pl-0" fluid>
            <div className="AppSidebar-Header" style={{ width: "152px" }}>
              <Link className="navbar-brand d-block AppSidebar-Brand" to="/">
                <img className="d-block" src={LogoImage} alt="CatchApp" />
              </Link>
            </div>

            {match.path === "/" && !match.isExact && (
              <button
                type="button"
                onClick={() => history.goBack()}
                className="d-block mr-auto ml-3 btn-goback"
              >
                <img
                  alt="back"
                  src={ArrowBackImage}
                  className="mr-4"
                  style={{ width: "30px" }}
                />
              </button>
            )}

            <Nav className="ml-auto align-items-center" navbar>
              <NavItem>
                <button
                  className="nav-item nav-link Header-Container__nav-links"
                  onClick={() => {
                    if (permit) {
                      getPublishers(1, { sort: "-created_at" });
                    }
                    resetEditing();
                    resetPublishersAll();
                    history.push("/");
                  }}
                >
                  Publishers
                </button>
              </NavItem>
              <NavItem>
                <button
                  className="nav-item nav-link Header-Container__nav-links"
                  onClick={() => {
                    history.push("/news");
                    resetEditing();
                    setOpen(!open);
                  }}
                >
                  news
                </button>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link Header-Container__nav-links"
                  to="/users"
                >
                  admin users
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link Header-Container__nav-links"
                  to="/appusers"
                >
                  app users
                </Link>
              </NavItem>
              <NavItem>
                <Link
                  className="nav-link Header-Container__nav-links"
                  to="/categories"
                >
                  categories
                </Link>
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
                  nav
                  className="badge-pill my-4 ListingActionButton dropdown-menu-toggle"
                >
                  CREATE
                  <img
                    src={ArrowDown}
                    alt="create"
                    className="ListingAction-Icon"
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="dropdown-item__general"
                    onClick={() => {
                      resetEditing();
                      resetPublishersAll();
                      history.push("/news/new");
                      setOpen(!open);
                    }}
                  >
                    NEWS
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <img
                  alt="header"
                  className="Header-Container__user-image__line"
                  src={SeparationLine}
                />
              </NavItem>

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="dropdown-menu-toggle">
                  <img
                    alt="avatar"
                    className="rounded-circle Header-Container__user-image"
                    src={login ? login.user.avatar : ""}
                  />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem
                    className="dropdown-item__general"
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    <img
                      src={EditImage}
                      className="icons-popover"
                      alt="profile button"
                    />
                    EDIT PROFILE
                  </DropdownItem>

                  <DropdownItem
                    className="dropdown-item__general"
                    onClick={() => {
                      logout();
                      history.push("/login");
                    }}
                  >
                    <img
                      src={DropdownLogoutImage}
                      className="icons-popover"
                      alt="profile button"
                    />
                    LOGOUT
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  login: state.login,
  permit: state.common.permit
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      logout,
      resetEditing,
      getPublishers,
      resetPublishersAll
    }
  )(AuthenticatedHeader)
);
