const initialState = {
  refreshLoading: false,
  loading: false,
  loadingAllTable: false,
  selectedPublishers: [],
  publishers: [],
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    currentPage: 1,
    from: null,
    last_page: null,
    per_page: null,
    to: null,
    total: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_PUBLISHERS_TABLE_LOADING": {
      return {
        ...state,
        loadingAllTable: true
      };
    }

    case "GET_PUBLISHERS_LOADING": {
      return {
        ...state,
        loading: true
      };
    }

    case "GET_PUBLISHERS_SUCCESS": {
      return {
        ...state,
        loading: false,
        publishers: [...action.payload.publishers],
        loadingAllTable: false,
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          currentPage: action.payload.meta.currentPage,
          from: action.payload.meta.from,
          last_page: action.payload.meta.last_page,
          per_page: action.payload.meta.per_page,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case "GET_PUBLISHERS_ERROR": {
      return {
        ...state,
        loading: false,
        loadingAllTable: false
      };
    }

    case "REFRESH_LOADING": {
      return {
        ...state,
        refreshLoading: true
      };
    }

    case "REFRESH_SUCCESS": {
      return {
        ...state,
        refreshLoading: false
      };
    }

    case "REFRESH_ERROR": {
      return {
        ...state,
        refreshLoading: false
      };
    }

    case "SET_SELECTED_PUBLISHERS": {
      return {
        ...state,
        selectedPublishers: [...state.selectedPublishers, action.payload.data]
      };
    }

    case "DELETE_SELECTED_PUBLISHERS": {
      return {
        ...state,
        selectedPublishers: state.selectedPublishers.filter(
          publisher => publisher.id !== action.payload.data
        )
      };
    }

    case "DELETE_PUBLISHERS_FROM_NEWS": {
      return {
        ...state,
        selectedPublishers: state.selectedPublishers.filter(
          publisher => publisher.id !== action.payload.id
        )
      };
    }

    case "RESET_PUBLISHERS_SELECTED_TABLE": {
      return {
        ...state,
        selectedPublishers: []
      };
    }

    case "RESET_PUBLISHERS_ALL": {
      return {
        ...state,
        selectedPublishers: [],
        publishers: []
      };
    }

    default: {
      return state;
    }
  }
};
