import React from "react";
import { withRouter } from "react-router-dom";
import { Input, Label, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import { setPreviousPath } from "../../../actions/common";
import { deletePublisherFromEdit } from "../../../actions/newsSingle";

import InputField from "../../InputField";
import PublisherInfoBox from "../../PublisherInfoBox";
import CategoryFilter from "../../CategoryFilter";
import AddArticleImage from "../../../images-eve/add_article.svg";

const NewsEditOne = ({
  setNewsSingleTitle,
  newsTitle,
  newsPriority,
  setNewsPriority,
  selectedCategories,
  setEditCategories,
  selectedPublishers,
  history,
  setPreviousPath,
  match,
  deletePublisherFromEdit
}) => {
  return (
    <Row className="m-0">
      <Col className="p-0 my-4">
        <Row className="m-0 align-items-center">
          <Col xs="3">
            <Label for="categories" className="news-new-font text-capitalize">
              category:
            </Label>
          </Col>
          <Col xs="9">
            <CategoryFilter
              selectedValues={selectedCategories}
              setCategories={setEditCategories}
            />
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="p-0 my-4">
        <Row className="m-0 align-items-center">
          <Col xs="3">
            <Label for="title" className="news-new-font text-capitalize">
              title:
            </Label>
          </Col>
          <Col xs="9">
            <InputField
              id="title"
              name="title"
              type="text"
              value={newsTitle}
              className="article__input-title"
              onChange={e => setNewsSingleTitle(e.target.value)}
            />
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="my-4 p-0">
        <Row className="m-0 align-items-center">
          <Col xs="3">
            <Label for="priority" className="news-new-font text-capitalize">
              priority
            </Label>
          </Col>
          <Col xs="9">
            <Input
              id="priority"
              type="select"
              onChange={e => setNewsPriority(e.target.value)}
              defaultValue={newsPriority || "1"}
            >
              <option value="1">Normal</option>
              <option value="2">Importante</option>
              <option value="3">Urgente</option>
            </Input>
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="p-0 my-4">
        <Row className="m-0 align-items-center">
          <Col xs="6">
            <Label className="article__items news-new-font text-capitalize">
              news selected:
            </Label>
            <span className="ml-3">{selectedPublishers.length}</span>
          </Col>
          <Col xs="6" className="text-right">
            <button
              onClick={() => {
                setPreviousPath(match.url);
                history.push("/");
              }}
              className="article__items d-flex align-items-center justify-content-end"
            >
              <img
                className="rounded-circle Header-Container__user-image mr-2"
                src={AddArticleImage}
                alt="add"
              />
              <span className="news-new-font text-capitalize">add article</span>
            </button>
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="p-0">
        {selectedPublishers.map(publisher => (
          <PublisherInfoBox
            key={publisher.id}
            publisher={publisher}
            onDelete={id => {
              deletePublisherFromEdit(id);
            }}
          />
        ))}
      </Col>
    </Row>
  );
};

export default withRouter(
  connect(
    null,
    {
      setPreviousPath,
      deletePublisherFromEdit
    }
  )(NewsEditOne)
);
