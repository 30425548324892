import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

class CircleCheckboxEditPublisher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: this.props.checked ? true : false
    };

    this.id =
      "checkbox_table_" +
      Math.random()
        .toString(36)
        .substring(7);

    this.checkRef = React.createRef();
  }

  onChange = event => {
    const checked = event.target.checked;

    this.setState({
      checked
    });

    this.props.onChange(this.props.data, checked);
  };

  render() {
    return (
      <div className="roundedOne">
        <input
          onChange={this.onChange}
          checked={this.state.checked}
          ref={this.checkRef}
          type="checkbox"
          value={this.state.checked}
          id={this.id}
          name={this.id}
        />
        <label htmlFor={this.id} />
      </div>
    );
  }
}

CircleCheckboxEditPublisher.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool
};

const mapStateToProps = state => ({
  permit: state.common.permit
});

export default connect(
  mapStateToProps,
  null
)(CircleCheckboxEditPublisher);
