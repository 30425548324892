import swal from 'sweetalert';

export const alertSuccess = message => {
    swal(message, {
        icon: 'success'
    });
};

export const alertError = message => {
    swal(message, {
        icon: 'error'
    });
}

export const alertConfirm = (message, success) => {
    swal(message, {
        dangerMode: true,
        buttons: true,
    })
    .then(null)
    .then(response => success());
};

