import React from 'react';

class GlobalSpinner extends React.Component {
    state = {};

    render() {
        return null;
    }
}

export default GlobalSpinner;
