import { NewsAPI } from "../middlewares/api";
import { alertError, alertSuccess } from "../helpers/alerts";
import moment from "moment";

export const getNews = id => async (dispatch, getState) => {
  dispatch({ type: "GET_NEWS_SINGLE_LOADING" });

  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await NewsAPI.newsSingle(authToken, id);

      dispatch({
        type: "GET_NEWS_SINGLE_SUCCESS",
        payload: {
          id: response.data.app_new.id,
          categories: response.data.app_new.categories.map(category => ({
            label: category.name,
            value: category.id
          })),
          title: response.data.app_new.title,
          priority: response.data.app_new.priority,
          api_news: response.data.app_new.api_news,
          gif: response.data.app_new.gif,
          body: response.data.app_new.body,
          actions: response.data.app_new.actions,
          unicode: {
            yes: response.data.app_new.actions[0].emoji,
            no: response.data.app_new.actions[1].emoji
          },
          genders: response.data.app_new.genders.map(gender =>
            gender.name.toLowerCase()
          ),
          starts_at: response.data.app_new.starts_at,
          ends_at: response.data.app_new.ends_at,
          frequency: "1"
        }
      });
    } catch (error) {
      dispatch({ type: "GET_NEWS_SINGLE_FAILURE" });

      if (error.response.status === 404) {
        alertError("Sorry that news you are looking for doesn't exists.");
      } else {
        alertError("Something bad happened.");
      }

      return error;
    }
  }
};

export const setNewsSingleTitle = title => ({
  type: "SET_NEWS_SINGLE_TITLE",
  payload: {
    title
  }
});

export const setNewsPriority = value => {
  const intValue = parseInt(value);

  return {
    type: "SET_SINGLE_PRIORITY",
    payload: {
      value: intValue
    }
  };
};

export const setEditCategories = list => async dispatch => {
  dispatch({
    type: "SET_EDIT_CATEGORIES",
    payload: {
      value: list
    }
  });
};

export const setNewsBodyEdit = text => ({
  type: "SET_EDIT_BODY",
  payload: {
    value: text
  }
});

export const removeGifEdit = () => ({
  type: "REMOVE_EDIT_GIF"
});

export const setGifEdit = gif => ({
  type: "SET_EDIT_GIF",
  payload: {
    gif
  }
});

export const setYesEdit = text => ({
  type: "SET_YES_EDIT_TEXT",
  payload: {
    text
  }
});

export const setYesEmojiEdit = ({ unified }) => dispatch => {
  dispatch({
    type: "SET_YES_EDIT_EMOJI",
    payload: {
      unified
    }
  });
};

export const removeYesEmojiEdit = () => ({
  type: "REMOVE_YES_EDIT_EMOJI"
});

export const setNoEdit = text => ({
  type: "SET_NO_EDIT_TEXT",
  payload: {
    text
  }
});

export const setNoEmojiEdit = ({ unified }) => dispatch => {
  dispatch({
    type: "SET_NO_EDIT_EMOJI",
    payload: {
      unified
    }
  });
};

export const removeNoEmojiEdit = () => ({
  type: "REMOVE_NO_EDIT_EMOJI"
});

export const addNewBodyEdit = (id, text) => ({
  type: "ADD_BODY_EDIT_TEXT",
  payload: {
    id,
    body: [text]
  }
});

export const setStartAtEdit = date => dispatch => {
  const formatedDate = moment(date).format("YYYY-MM-DD hh:mm:ss");

  // const formatDate = format(new Date(date), "YYYY-MM-DD hh:mm:ss", {
  //   awareOfUnicodeTokens: true
  // });

  dispatch({
    type: "SET_EDIT_START_AT",
    payload: {
      value: formatedDate
    }
  });
};

export const setEndsAtEdit = date => dispatch => {
  const formatedDate = moment(date).format("YYYY-MM-DD hh:mm:ss");

  // const formatDate = format(new Date(date), "YYYY-MM-DD hh:mm:ss", {
  //   awareOfUnicodeTokens: true
  // });

  dispatch({
    type: "SET_EDIT_ENDS_AT",
    payload: {
      value: formatedDate
    }
  });
};

export const setAgeRangeEdit = value => async dispatch => {
  const idArr = value.map(item => item.value);

  dispatch({
    type: "SET_EDIT_AGE_RANGES",
    payload: {
      value: idArr
    }
  });
};

export const setNewsGenderEdit = list => async dispatch => {
  const genderList = list.map(item => item.value);

  dispatch({
    type: "SET_EDIT_GENDER",
    payload: {
      value: genderList
    }
  });
};

export const setPublisherToEdit = publisher => dispatch => {
  const parsedPublisher = {
    ...publisher,
    body: []
  };

  dispatch({
    type: "SET_PUBLISHER_TO_EDIT",
    payload: {
      publisher: parsedPublisher
    }
  });
};

export const deletePublisherFromEdit = publisherId => ({
  type: "DELETE_PUBLISHER_FROM_EDIT",
  payload: {
    value: publisherId
  }
});

export const deleteNews = id => async (dispatch, getState) => {
  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await NewsAPI.deleteNews(authToken, id);

      if (response.status === 204) {
        alertSuccess("News have been deleted succefully");
      }
    } catch (error) {
      if (error.response.status === 404) {
        alertError("Sorry that news you are looking for doesn't exists.");
      } else {
        alertError("Something bad happened.");
      }

      return error;
    }
  }
};
