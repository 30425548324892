import React from "react";
import { Row, Col, Container } from "reactstrap";
import FormForgotPassword from "./FormForgotPassword";
import PageTransition from "../PageTransition";

const ForgotPassword = () => (
  <div className="h-100-no-navbar d-flex align-items-center">
    <div className="w-100">
      <PageTransition>
        <Row className="m-0" id="content">
          <Container>
            <Row className="ml-0 mr-0 align-items-center">
              <Col xs={12} sm={4} md={5}>
                <div className="TitlesColumLeft">
                  <div className="TitlesColumLeft-First_top">Forgot your</div>
                  <h1 className="TitlesColumLeft-First">
                    <span className="TitlesColumLeft-First--Down">PASS-</span>
                    WORD?
                  </h1>
                  <h2 className="TitlesColumLeft-Second">
                    We’ll start by sending you an Email...{" "}
                  </h2>
                  <h4 className="TitlesColumLeft-Third">
                    Complete your user information.
                  </h4>
                </div>
              </Col>
              <Col xs={12} md={7} className="d-flex align-items-center">
                <FormForgotPassword />
              </Col>
            </Row>
          </Container>
        </Row>
      </PageTransition>
    </div>
  </div>
);

export default ForgotPassword;
