import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import GlobalSpinner from "./GlobalSpinner";
import Panel from "./pages/Panel";
import UnauthenticatedPanel from "./Login/UnauthenticatedPanel";

import dotenv from "dotenv";
dotenv.config();

const App = () => (
  <BrowserRouter>
    <GlobalSpinner />
    <Switch>
      <Route path="/login" component={UnauthenticatedPanel} />
      <Route path="/" component={Panel} />
    </Switch>
  </BrowserRouter>
);

export default App;
