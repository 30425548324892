import React from "react";
import CloseFilter from "../images-eve/close__filter.svg";

const FilterList = ({ handleDeleteFilter, filters }) => {
  const handleClick = name => {
    handleDeleteFilter(name);
  };

  return (
    <div className="filter-list mt-3">
      {filters.sort && (
        <span className="label-filter-list">
          sort by: {filters.sort}{" "}
          <button
            className="label-filter-list__btn"
            onClick={() => handleClick("sort")}
          >
            <img
              className="label-filter-list__svg"
              src={CloseFilter}
              alt="label"
            />
          </button>
        </span>
      )}
      {filters.from && filters.to && !filters.today && (
        <>
          <span className="label-filter-list">from: {filters.from}</span>
          <span className="label-filter-list">to: {filters.to}</span>
        </>
      )}
      {filters.from && filters.to && filters.today && (
        <span className="label-filter-list">
          today: {filters.today}{" "}
          <button
            className="label-filter-list__btn"
            onClick={() => handleClick("today")}
          >
            <img
              className="label-filter-list__svg"
              src={CloseFilter}
              alt="label"
            />
          </button>
        </span>
      )}
      {filters.search && (
        <span className="label-filter-list">
          search for: {filters.search}{" "}
          <button
            className="label-filter-list__btn"
            onClick={() => handleClick("search")}
          >
            <img
              className="label-filter-list__svg"
              src={CloseFilter}
              alt="label"
            />
          </button>
        </span>
      )}
      {filters.categories && filters.categories.length > 0 && (
        <span className="label-filter-list">
          {filters.categories.map(item => item.label).join(" or ")}
          <button
            className="label-filter-list__btn"
            onClick={() => {
              handleClick("categories");
            }}
          >
            <img
              className="label-filter-list__svg"
              src={CloseFilter}
              alt="label"
            />
          </button>
        </span>
      )}
    </div>
  );
};

export default FilterList;
