import React, { useEffect } from "react";
import { Row, Col, Container } from "reactstrap";

import { connect } from "react-redux";

import FormLogin from "./FormLogin";
import { setGlobalStyle } from "../../actions/common";
import PageTransition from "../PageTransition";

const Login = ({ setGlobalStyle }) => {
  useEffect(() => {
    setGlobalStyle("AppContainer--normal_image");

    return () => setGlobalStyle("");
  });

  return (
    <div className="h-100-no-navbar d-flex align-items-center">
      <div className="w-100">
        <PageTransition>
          <Container fluid>
            <Row className="m-0" id="content">
              <Col xs={12} md={{ size: 10, offset: 1 }} className="LoginPage">
                <Col xs={12} sm={10}>
                  <div className="TitlesColumLeft">
                    <h1 className="TitlesColumLeft-First text-blue">
                      <span className="TitlesColumLeft-First--Down">
                        WELCOME
                      </span>
                      TO CATCHAPP
                    </h1>
                  </div>
                </Col>
                <Col xs={12} sm={6} className="col-12 col-sm-6">
                  <FormLogin />
                </Col>
              </Col>
            </Row>
          </Container>
        </PageTransition>
      </div>
    </div>
  );
};

export default connect(
  null,
  {
    setGlobalStyle
  }
)(Login);
