import React, { Component } from "react";
import styled from "styled-components";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";

import { connect } from "react-redux";
import {
  getCategories,
  getAgeRanges,
  saveDraftNews,
  publishNews
} from "../../actions/common";
import { getProvinces, getLocations } from "../../actions/addresses";

import PageTransition from "../PageTransition";
import NewsNewOne from "../NewsNewOne";
import NewsNewTwo from "../NewsNewTwo";
import NewsNewThree from "../NewsNewThree";
import TabsNavigation from "../TabsNavigation";
import LivePreview from "../LivePreview";
import TableSpinner from "../TableSpinner";

const NavBtn = styled(NavLink)`
  cursor: pointer;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;

class NewsNew extends Component {
  state = {
    submitting: false,
    tab: "1"
  };

  async componentDidMount() {
    const {
      getCategories,
      getProvinces,
      getLocations,
      getAgeRanges
    } = this.props;

    await Promise.all([
      await getCategories(),
      await getProvinces(),
      await getLocations(),
      await getAgeRanges()
    ]);
  }

  handleTab = tab => {
    this.setState({
      tab
    });
  };

  toggleLoading = () => {
    this.setState({
      submitting: !this.state.submitting
    });
  };

  componentWillUnmount() {
    this.setState({
      submitting: false
    });
  }

  handleSave = async () => {
    const { saveDraftNews, history, news } = this.props;

    this.toggleLoading();

    const response = await saveDraftNews(news, true);

    if (response && response.status === 200) {
      history.push("/news");
      return;
    }

    this.toggleLoading();
  };

  handleSaveAndPublish = async () => {
    const { saveDraftNews, history, news, publishNews } = this.props;

    this.toggleLoading();

    const response = await saveDraftNews(news);
    if (response && response.status === 200) {
      const { id } = response.data.app_new;
      const createResponse = await publishNews(id);
      if (createResponse && createResponse.status === 204) {
        history.push("/news");
      }
    }

    this.toggleLoading();
  };

  render() {
    const { submitting, tab } = this.state;
    const {
      catchappMessage,
      gif,
      apiNews,
      yesEmoji,
      noEmoji,
      yesText,
      noText
    } = this.props;

    if (submitting) {
      return (
        <SpinnerWrapper>
          <TableSpinner />
        </SpinnerWrapper>
      );
    }

    return (
      <PageTransition>
        <Row className="ml-0 mr-0 justify-content-center create-new">
          {/* First Column */}
          <NewsNewOne />

          {/* Second Column Navs */}
          <Col xs="12" sm="12" md="8">
            <Nav tabs className="row nav-tabs_active">
              <NavItem className="col-12 col-sm-6 p-0 nav-item">
                <NavBtn
                  className={`nav-link normal-item ${tab === "1" && "active"}`}
                  onClick={() => this.handleTab("1")}
                >
                  Write new
                  <hr className="line-title" />
                </NavBtn>
              </NavItem>
              <NavItem className="col-12 col-sm-6 p-0 nav-item">
                <NavBtn
                  className={`nav-link normal-item ${tab === "2" && "active"}`}
                  onClick={() => this.handleTab("2")}
                >
                  Publishing details
                  <hr className="line-title" />
                </NavBtn>
              </NavItem>
            </Nav>

            <Row>
              <Col sm="6" className="p-0">
                <TabContent
                  activeTab={tab}
                  className="tab-content_create-new p-4"
                >
                  <TabPane tabId="1">
                    <NewsNewTwo />
                  </TabPane>
                  <TabPane tabId="2">
                    <NewsNewThree />
                  </TabPane>
                </TabContent>
              </Col>
              <Col sm="6" className="p-0">
                <LivePreview
                  catchappMessage={catchappMessage}
                  gif={gif}
                  apiNews={apiNews}
                  yesEmoji={yesEmoji}
                  noEmoji={noEmoji}
                  yesText={yesText}
                  noText={noText}
                />
              </Col>
            </Row>

            <Row>
              <TabsNavigation
                tab={tab}
                setTab={this.handleTab}
                saveAndPublish={this.handleSaveAndPublish}
                save={this.handleSave}
                saveText="Save"
                publishText="Save & Publish"
              />
            </Row>
          </Col>
        </Row>
      </PageTransition>
    );
  }
}

const mapStateToProps = state => ({
  news: state.news.news,
  catchappMessage: state.news.news.body,
  gif: state.news.news.gif,
  apiNews: state.news.news.api_news,
  yesEmoji: state.news.emojis.yes,
  noEmoji: state.news.emojis.no,
  yesText: state.news.news.actions[0].name,
  noText: state.news.news.actions[1].name
});

export default connect(
  mapStateToProps,
  {
    saveDraftNews,
    publishNews,
    getCategories,
    getProvinces,
    getLocations,
    getAgeRanges
  }
)(NewsNew);
