import { CategoryAPI, NewsAPI } from "../middlewares/api";
import { alertError, alertSuccess } from "../helpers/alerts";
import ErrorReducer from "../helpers/ErrorReducer";

export const setGlobalStyle = style => ({
  type: "SET_WRAPPER_CLASS",
  payload: style
});

export const setPreviousPath = path => ({
  type: "SET_PREVIOUS_PATH",
  payload: {
    path,
    permit: true
  }
});

export const deletePreviousPath = () => ({
  type: "DELETE_PREVIOUS_PATH",
  payload: {
    permit: false
  }
});

export const getCategories = (page = false) => async dispatch => {
  try {
    dispatch({ type: "LOADING_CATEGORIES" });
    dispatch({ type: "CATEGORIES_LOADING" });

    const response = await CategoryAPI.categoriesList();
    let categories;

    if (!page) {
      categories = response.data.categories.map(category => ({
        label: category.name,
        value: category.id
      }));
    } else {
      categories = response.data.categories;
    }

    dispatch({
      type: "GET_CATEGORIES_SUCCESS",
      payload: {
        categories
      }
    });
  } catch (error) {
    dispatch({ type: "GET_CATEGORIES_FAILURE" });
    alertError("Something bad happened.");
  }
};

export const getAgeRanges = () => async dispatch => {
  const response = await NewsAPI.ageRanges();

  const ranges = response.data.age_ranges.map(range => ({
    label: range.name,
    value: range.id
  }));

  dispatch({
    type: "GET_AGE_RANGES",
    payload: {
      value: ranges
    }
  });
};

export const saveDraftNews = (news, justDraft = false) => async (
  dispatch,
  getState
) => {
  const authToken = getState().login.authToken;

  const parsedNews = {
    ...news,
    categories: news.categories.map(category => category.value),
    api_news: news.api_news.map(item => ({
      api_news_id: item.id,
      body: item.body
    }))
  };

  try {
    const response = await NewsAPI.saveDraftNews(authToken, parsedNews);

    if (justDraft) {
      if (response.status === 200) {
        alertSuccess("Your news have been successfully created as draft.");
        // Reset state from publishers reducer
        dispatch({
          type: "RESET_PUBLISHERS_SELECTED_TABLE"
        });

        // Reset state from news reducer
        dispatch({
          type: "RESET_NEWS_STATE"
        });
      }
    }

    return response;
  } catch (error) {
    if (error.response.status !== 422) {
      alertError("Something bad happened.");
    }

    if (error.response.status === 422) {
      // Conver all error objects to a single array of errors
      const errorList = ErrorReducer(error.response.data.errors);
      // Stringify all error array, this is horrible :(
      const errorStringify = errorList.reduce(
        (prev, next) => `${prev}\n${next}`
      );

      alertError(errorStringify);
    }
  }
};

export const publishNews = id => async (dispatch, getState) => {
  const authToken = getState().login.authToken;

  try {
    const response = await NewsAPI.publishNews(authToken, id);

    if (response.status === 204) {
      alertSuccess("News published successfully.");
      // Reset state from publishers reducer
      dispatch({
        type: "RESET_PUBLISHERS_SELECTED_TABLE"
      });

      // Reset state from news reducer
      dispatch({
        type: "RESET_NEWS_STATE"
      });
    }
    return response;
  } catch (error) {
    if (error.response.status !== 422) {
      alertError("Something bad happened.");
    }

    if (error.response.status === 422) {
      // Conver all error objects to a single array of errors
      const errorList = ErrorReducer(error.response.data.errors);
      // Stringify all error array, this is horrible :(
      const errorStringify = errorList.reduce(
        (prev, next) => `${prev}\n${next}`
      );

      alertError(errorStringify);
    }
  }
};

export const updateDraftNews = (id, news, justDraft = false) => async (
  dispatch,
  getState
) => {
  if (getState().login) {
    const authToken = getState().login.authToken;

    // Parsing categories and deleting the ID from the actions arr
    const parsedNews = {
      ...news,
      api_news: news.api_news.map(item => ({
        api_news_id: item.id,
        body: item.body
      })),
      categories: news.categories.map(category => category.value),
      actions: news.actions.map(action => ({
        name: action.name,
        action: action.action_type,
        weight: 1,
        emoji: action.emoji
      }))
    };

    try {
      const response = await NewsAPI.updateDraftNews(authToken, id, parsedNews);

      if (justDraft) {
        if (response.status === 200) {
          alertSuccess(
            `Your news: "${news.title}" have been successfully updated.`
          );
        }
      }

      return response;
    } catch (error) {
      if (error.response.status === 422) {
        const errorList = ErrorReducer(error.response.data.errors);
        const errorStringify = errorList.reduce(
          (prev, next) => `${prev}\n${next}`
        );
        alertError(errorStringify);
      } else {
        alertError("Something bad happened.");
      }
    }
  }
};

export const resetEditing = () => ({
  type: "RESET_EDITING"
});

export const resetPublishersAll = () => ({
  type: "RESET_PUBLISHERS_ALL"
});
