import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import moment from "moment";
import { connect } from "react-redux";

import CircleCheckboxEditPublisher from "../CircleCheckboxEditPublisher";
import CircleCheckboxPublisher from "../CircleCheckboxPublisher";

import UsedIconCheckImage from "../../images-eve/used-icon-check.svg";
import ArrowListingTop from "../../images-eve/arrow-listing-top.svg";
import ArrowListingDown from "../../images-eve/arrow-listing.svg";

class PublishersTable extends Component {
  state = {
    publishers: [],
    tableSpinner: false
  };

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.tableSpinner) {
      return {
        tableSpinner: props.loading
      };
    }

    return null;
  }

  onCheckboxChange = (data, checked) => {
    const {
      setSelectedPublishers,
      deleteSelectedPublishers,
      permit,
      handleAddPublisherToEdit,
      handleDeletePublisherToEdit
    } = this.props;

    if (permit) {
      if (checked) {
        handleAddPublisherToEdit(data);
      } else {
        handleDeletePublisherToEdit(data.id);
      }
    } else {
      if (checked) {
        setSelectedPublishers(data);
      } else {
        deleteSelectedPublishers(data.id);
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const { publishersList, selectedPublishers, permit } = this.props;

    if (prevState.tableSpinner) {
      const parsedPublishers = publishersList.map(publisher => {
        return {
          checkbox: permit ? (
            <span>
              <CircleCheckboxEditPublisher
                onChange={this.onCheckboxChange}
                data={publisher}
                checked={prevProps.selectedPublishersFromEdit
                  .map(publisher => publisher.id)
                  .includes(publisher.id)}
              />
            </span>
          ) : (
            <span>
              <CircleCheckboxPublisher
                onChange={this.onCheckboxChange}
                data={publisher}
                checked={selectedPublishers
                  .map(publisher => publisher.id)
                  .includes(publisher.id)}
              />
            </span>
          ),
          title: publisher.title,
          api_id: publisher.portal,
          created_at: moment(publisher.created_at).format("D MMMM Y"),
          used: publisher.is_used ? (
            <img
              src={UsedIconCheckImage}
              alt="checked"
              className="img-fluid d-block mx-auto is-used-icon"
            />
          ) : null
        };
      });

      this.setState({
        publishers: [...parsedPublishers]
      });
    }
  }

  handleFieldOrder = async (field, order) => {
    const { handleSortByField } = this.props;
    handleSortByField(field, order);
  };

  sortCaret = (order, column) => {
    if (order === "asc") {
      return (
        <img src={ArrowListingTop} alt="" className="Listing__arrow-order" />
      );
    }

    if (order === "desc") {
      return (
        <img src={ArrowListingDown} alt="" className="Listing__arrow-order" />
      );
    }

    return null;
  };

  noDataIndication = () => {
    if (this.state.tableSpinner) return null;

    return <span>No information</span>;
  };

  paginationOptions = {
    page: null,
    paginationSize: null,
    totalSize: null,
    pageStartIndex: 1,
    hideSizePerPage: true,
    onPageChange: (page, sizePerPage) => {
      const { handlePaginator } = this.props;
      handlePaginator(page);
    }
  };

  // Columns
  columns = [
    {
      dataField: "checkbox",
      text: "",
      style: {
        width: "60px"
      }
    },
    {
      dataField: "title",
      width: "200px",
      text: (
        <span>
          <img
            src="images-eve/icon-note.svg"
            alt=""
            className="Listing__view-icon Listing__view-icon_vertical"
          />
          TITLE NOTE
        </span>
      ),
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret,
      classes: ["title-news__max-width"]
    },
    {
      dataField: "api_id",
      text: (
        <span>
          <img
            src="images-eve/icon-owner.svg"
            alt=""
            className="Listing__view-icon"
          />
          OWNER
        </span>
      ),
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret
    },
    {
      dataField: "created_at",
      text: (
        <span>
          <img
            src="images-eve/icon-date.svg"
            alt=""
            className="Listing__view-icon"
          />
          DATE
        </span>
      ),
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret
    },
    {
      dataField: "used",
      text: (
        <span>
          <img
            src="images-eve/icon-date.svg"
            alt=""
            className="Listing__view-icon"
          />
          USED
        </span>
      ),
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret
    }
  ];

  render() {
    const { publishers } = this.state;

    const pagination = {
      ...this.paginationOptions,
      page: this.props.currentPage,
      paginationSize: this.props.paginationSize,
      totalSize: this.props.totalSize
    };

    return (
      <div className="Listing">
        <div className="table-responsive" style={{ overflow: "hidden" }}>
          <BootstrapTable
            classes="table listing__table"
            remote={{
              pagination: true,
              sort: true,
              filter: true
            }}
            bordered={false}
            onTableChange={(type, fields) => null}
            keyField="title"
            columns={this.columns}
            noDataIndication={this.noDataIndication}
            data={publishers}
            pagination={paginationFactory(pagination)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedPublishers: state.publishers.selectedPublishers,
  currentPage: state.publishers.meta.currentPage,
  paginationSize: state.publishers.meta.per_page,
  totalSize: state.publishers.meta.total,
  selectedPublishersFromEdit: state.newsSingle.news.api_news
});

export default connect(
  mapStateToProps,
  null
)(PublishersTable);
