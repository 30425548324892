import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import {
  getNews,
  setNewsSingleTitle,
  setNewsPriority,
  setEditCategories
} from "../../../actions/newsSingle";
import {
  getCategories,
  getAgeRanges,
  updateDraftNews,
  publishNews
} from "../../../actions/common";
import { getProvinces, getLocations } from "../../../actions/addresses";

import TableSpinner from "../../TableSpinner";
import PageTransition from "../../PageTransition";
import NewsEditOne from "./NewsEditOne";
import NewsEditTwo from "./NewsEditTwo";
import NewsEditThree from "./NewsEditThree";
import LivePreview from "../../LivePreview";
import TabsNavigation from "../../TabsNavigation";

const NavBtn = styled(NavLink)`
  cursor: pointer;
`;

class NewsEdit extends Component {
  state = {
    tab: "1",
    submitting: false
  };

  async componentDidMount() {
    const {
      getNews,
      getCategories,
      getAgeRanges,
      getProvinces,
      getLocations,
      history,
      previousPublishers,
      previousNewsEdit,
      selectedPublishers
    } = this.props;
    const { id } = this.props.match.params;

    if (
      previousNewsEdit === parseInt(id) &&
      !isEqual(previousPublishers, selectedPublishers)
    ) {
      return;
    }

    const response = await Promise.all([
      getNews(id),
      getCategories(),
      getProvinces(),
      getLocations(),
      getAgeRanges()
    ]);

    if (response[0] && response[0].response.status === 404) {
      history.push("/news");
    }
  }

  handleTab = tab => {
    this.setState({
      tab
    });
  };

  toggleLoading = () => {
    this.setState({
      submitting: !this.state.submitting
    });
  };

  handleSave = async () => {
    this.toggleLoading();
    const { news, updateDraftNews, history } = this.props;
    const { id } = this.props.match.params;
    const response = await updateDraftNews(id, news, true);

    if (response.status === 200) {
      history.push("/news");
      return;
    }

    this.toggleLoading();
  };

  handlePublish = async () => {
    this.toggleLoading();
    const { news, updateDraftNews, history, publishNews } = this.props;
    const { id } = this.props.match.params;

    const response = await updateDraftNews(id, news);
    if (response && response.status === 200) {
      const { id } = response.data.app_new;
      const createResponse = await publishNews(id);
      if (createResponse && createResponse.status === 204) {
        history.push("/news");
        return;
      }
    }

    this.toggleLoading();
  };

  render() {
    const {
      setNewsSingleTitle,
      newsTitle,
      setNewsPriority,
      newsPriority,
      selectedCategories,
      setEditCategories,
      selectedPublishers,
      catchappMessage,
      gif,
      apiNews,
      yesEmoji,
      noEmoji,
      yesText,
      noText,
      loading
    } = this.props;

    const { tab, submitting } = this.state;

    if (loading || submitting) {
      return (
        <div className="loading-centered">
          <TableSpinner />
        </div>
      );
    }

    return (
      <PageTransition>
        <Row className="ml-0 mr-0 justify-content-center create-new">
          {/* First Column */}
          <Col md="4" className="mt-5">
            <NewsEditOne
              setNewsSingleTitle={setNewsSingleTitle}
              newsTitle={newsTitle}
              setNewsPriority={setNewsPriority}
              newsPriority={newsPriority}
              selectedCategories={selectedCategories}
              setEditCategories={setEditCategories}
              selectedPublishers={selectedPublishers}
            />
          </Col>

          <Col xs="12" sm="12" md="8">
            <Nav tabs className="row nav-tabs_active">
              <NavItem className="col-12 col-sm-6 p-0 nav-item">
                <NavBtn
                  className={`nav-link normal-item ${tab === "1" && "active"}`}
                  onClick={() => {
                    this.setState({ tab: "1" });
                  }}
                >
                  Write new
                  <hr className="line-title" />
                </NavBtn>
              </NavItem>
              <NavItem className="col-12 col-sm-6 p-0 nav-item">
                <NavBtn
                  className={`nav-link normal-item ${tab === "2" && "active"}`}
                  onClick={() => {
                    this.setState({ tab: "2" });
                  }}
                >
                  Publishing details
                  <hr className="line-title" />
                </NavBtn>
              </NavItem>
            </Nav>

            <Row>
              <Col sm="6" className="p-0">
                <TabContent
                  activeTab={tab}
                  className="tab-content_create-new p-4"
                >
                  <TabPane tabId="1">
                    <NewsEditTwo />
                  </TabPane>
                  <TabPane tabId="2">
                    <NewsEditThree />
                  </TabPane>
                </TabContent>
              </Col>
              <Col sm="6" className="p-0">
                <LivePreview
                  catchappMessage={catchappMessage}
                  gif={gif}
                  apiNews={apiNews}
                  yesEmoji={yesEmoji}
                  noEmoji={noEmoji}
                  yesText={yesText}
                  noText={noText}
                />
              </Col>
            </Row>

            <Row>
              <TabsNavigation
                tab={tab}
                setTab={this.handleTab}
                saveAndPublish={this.handlePublish}
                save={this.handleSave}
                saveText="Save"
                publishText="Save & Publish"
              />
            </Row>
          </Col>
        </Row>
      </PageTransition>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.newsSingle.loading,
  news: state.newsSingle.news,
  newsTitle: state.newsSingle.news.title,
  newsPriority: state.newsSingle.news.priority,
  selectedCategories: state.newsSingle.news.categories,
  selectedPublishers: state.newsSingle.news.api_news,
  catchappMessage: state.newsSingle.news.body,
  gif: state.newsSingle.news.gif,
  apiNews: state.newsSingle.news.api_news,
  yesEmoji: state.newsSingle.emojis.yes,
  noEmoji: state.newsSingle.emojis.no,
  yesText: state.newsSingle.news.actions[0].name,
  noText: state.newsSingle.news.actions[1].name,
  previousPublishers: state.newsSingle.previousPublishers,
  previousNewsEdit: state.newsSingle.id
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getNews,
      setNewsSingleTitle,
      setNewsPriority,
      setEditCategories,
      getCategories,
      getProvinces,
      getLocations,
      getAgeRanges,
      updateDraftNews,
      publishNews
    }
  )(NewsEdit)
);
