import React from "react";
import { Row, Col, Label, FormGroup, Input } from "reactstrap";

import { connect } from "react-redux";
import {
  setNewsBody,
  removeGif,
  setGif,
  setNewsYesAnswer,
  setYesEmoji,
  removeYesEmoji,
  setNewsNoAnswer,
  setNoEmoji,
  removeNoEmoji,
  addNewBody
} from "../actions/news";

import AddGif from "../images-eve/add-gif.svg";
import RemoveGif from "../images-eve/remove-gif.svg";
import GifPicker from "./GifPicker";
import AddRemoveGifEmoji from "./AddRemoveGifEmoji";
import RemoveGifBtnWrapper from "./styles/RemoveGifBtnWrapper";
import Answer from "./Answer";

import MessagesListContainer from "./MessagesListContainer";

const NewsNewTwo = ({
  newsBody,
  setNewsBody,
  newsGif,
  removeGif,
  setNewsYesAnswer,
  setYesEmoji,
  removeYesEmoji,
  nameYes,
  emojiYes,
  setNewsNoAnswer,
  setNoEmoji,
  removeNoEmoji,
  nameNo,
  emojiNo,
  apiNews,
  addNewBody,
  setGif
}) => (
  <Row>
    <Col>
      <FormGroup className="my-4">
        <Row form>
          <Col>
            <Label for="body" className="create-news__titles-news">
              CatchApp Messages
            </Label>
          </Col>
          <Col className="text-right">
            <span className="create-news__titles-news">
              {newsBody.length}/140
            </span>
          </Col>
        </Row>
        <Row form>
          <Col>
            <Input
              id="body"
              type="textarea"
              name="body"
              maxLength="140"
              value={newsBody}
              onChange={e => setNewsBody(e.target.value)}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="my-4">
        <Row form>
          <Col>
            {newsGif.length <= 0 && (
              <GifPicker image={AddGif} text="Add Gif" setGifSubmit={setGif} />
            )}
            {newsGif.length > 0 && (
              <RemoveGifBtnWrapper onClick={() => removeGif()}>
                <AddRemoveGifEmoji image={RemoveGif} text="Remove Gif" />
              </RemoveGifBtnWrapper>
            )}
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="create-news__titles-news my-4">
        <Row form>
          <Col xs="3">
            <span>Answers</span>
          </Col>
          <Col xs="9">
            <Answer
              letter="Y"
              setAnswer={setNewsYesAnswer}
              setEmoji={setYesEmoji}
              removeEmoji={removeYesEmoji}
              text={nameYes}
              emoji={emojiYes}
            />

            <Answer
              letter="N"
              setAnswer={setNewsNoAnswer}
              setEmoji={setNoEmoji}
              removeEmoji={removeNoEmoji}
              text={nameNo}
              emoji={emojiNo}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup className="my-4">
        <MessagesListContainer apiNews={apiNews} addNewBody={addNewBody} />
      </FormGroup>
    </Col>
  </Row>
);

const mapStateToProps = state => ({
  newsBody: state.news.news.body,
  newsGif: state.news.news.gif,
  nameYes: state.news.news.actions[0].name,
  emojiYes: state.news.emojis.yes,
  nameNo: state.news.news.actions[1].name,
  emojiNo: state.news.emojis.no,
  apiNews: state.news.news.api_news
});

export default connect(
  mapStateToProps,
  {
    setNewsBody,
    removeGif,
    setNewsYesAnswer,
    setYesEmoji,
    removeYesEmoji,
    setNewsNoAnswer,
    setNoEmoji,
    removeNoEmoji,
    addNewBody,
    setGif
  }
)(NewsNewTwo);
