import React, { Fragment, Component } from "react";
import { Modal, ModalBody, ModalFooter, Row, Col, Spinner } from "reactstrap";
import moment from "moment";

import { connect } from "react-redux";

import {
  getPublishers,
  refreshPublishers,
  setSelectedPublishers,
  deleteSelectedPublishers
} from "../../actions/publishers";

import {
  setPublisherToEdit,
  deletePublisherFromEdit
} from "../../actions/newsSingle";

import ArrowUpdate from "../../images-eve/arrow_update.svg";
import SubmitButton from "../SubmitButton";
import PublishersTable from "./PublishersTable";
import RangePicker from "../RangePicker";
import SearchBar from "../SearchBar";
import FilterList from "../FilterList";
import PageTransition from "../PageTransition";
import TableSpinner from "../TableSpinner";

class PublisherList extends Component {
  state = {
    modal: false,
    filters: {
      today: null,
      sort: "-created_at",
      search: null,
      from: null,
      to: null
    },
    newsToEdit: []
  };

  async componentDidMount() {
    const { getPublishers } = this.props;
    const { filters } = this.state;
    await getPublishers(1, filters);
  }

  handleSubmit = async (page, filters) => {
    const { getPublishers } = this.props;
    await getPublishers(page, filters);
  };

  handleSearch = term => {
    if (term.length > 0) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: term,
            title: term
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: null,
            title: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }
  };

  onRangePickerSubmit = async (from, to) => {
    const dateFrom = moment(from).format("YYYY-MM-DD");
    const dateTo = moment(to).format("YYYY-MM-DD");

    if (dateFrom !== dateTo) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            from: dateFrom,
            to: dateTo,
            today: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    } else {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            from: dateFrom,
            to: dateTo,
            today: "today"
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }
  };

  handleSortByField = async (field, order) => {
    let sort;
    if (order === "desc") {
      sort = `-${field}`;
    } else {
      sort = field;
    }

    this.setState(
      {
        filters: {
          ...this.state.filters,
          sort
        }
      },
      () => this.handleSubmit(1, this.state.filters)
    );
  };

  onRefreshExternalPublishers = async () => {
    const { refreshPublishers, getPublishers } = this.props;
    await refreshPublishers();
    await getPublishers(1, this.state.filters);
  };

  handlePaginator = async page => {
    this.handleSubmit(page, this.state.filters);
  };

  handleDeleteFilter = async filterName => {
    if (filterName === "sort") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            sort: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }

    if (filterName === "today") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            today: null,
            from: null,
            to: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }

    if (filterName === "search") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            search: null
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }

    if (filterName === "categories") {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            categories: []
          }
        },
        () => this.handleSubmit(1, this.state.filters)
      );
    }
  };

  handleModalOpen = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleModalConfirm = () => {
    const { history } = this.props;
    history.push("/news/new");
  };

  handleAddPublisherToEdit = publisher => {
    const { setPublisherToEdit } = this.props;
    setPublisherToEdit(publisher);
  };

  handleDeletePublisherToEdit = publisherId => {
    const { deletePublisherFromEdit } = this.props;
    deletePublisherFromEdit(publisherId);
  };

  render() {
    const {
      selectedPublishers,
      loading,
      publishersCount,
      refreshLoading,
      publishersList,
      setSelectedPublishers,
      deleteSelectedPublishers,
      history,
      previousPath,
      permit
    } = this.props;

    const { deleteCaret } = this.state;

    return (
      <Fragment>
        <PageTransition>
          <Row className="ml-0 mr-0 justify-content-center">
            <Col xs={12} sm={11} className="mt-5">
              <Row className="ml-0 mr-0 my-4 d-flex justify-content-between align-items-center">
                <h1 className="mb-0">
                  {permit && "ADD"} PUBLISHERS {permit && "TO EDIT"}
                </h1>
                <RangePicker onSubmit={this.onRangePickerSubmit} />
              </Row>
            </Col>

            <Col xs={12} sm={11}>
              <Row>
                <Col xs={12} sm={4}>
                  <div className="d-flex">
                    <SearchBar onSubmit={this.handleSearch} />
                    <SubmitButton
                      onClick={this.onRefreshExternalPublishers}
                      className="btn update__list"
                      style={{ background: "#ff8155" }}
                      disabled={refreshLoading}
                    >
                      <img
                        src={ArrowUpdate}
                        className="arrow-update__img"
                        alt="arrow update"
                      />
                    </SubmitButton>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12} sm={11}>
                  <span className="d-block mt-4 search-bar__result-search">
                    Se han encontrado{" "}
                    {loading ? (
                      <span>
                        <Spinner size="sm" color="secondary" />
                      </span>
                    ) : (
                      publishersCount || "0"
                    )}{" "}
                    elementos
                  </span>
                  <FilterList
                    filters={this.state.filters}
                    handleDeleteFilter={this.handleDeleteFilter}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={12} sm={11}>
              <div className="position-relative table-min-height">
                {loading && (
                  <div className="loading-table">
                    <TableSpinner />
                  </div>
                )}

                <PublishersTable
                  publishersList={publishersList}
                  handlePaginator={this.handlePaginator}
                  handleSortByField={this.handleSortByField}
                  setSelectedPublishers={setSelectedPublishers}
                  deleteSelectedPublishers={deleteSelectedPublishers}
                  loading={loading}
                  deleteCaret={deleteCaret}
                  permit={permit}
                  handleAddPublisherToEdit={this.handleAddPublisherToEdit}
                  handleDeletePublisherToEdit={this.handleDeletePublisherToEdit}
                />
              </div>
            </Col>

            <Col xs={12} className="my-5">
              {selectedPublishers.length > 0 && !permit && (
                <button
                  onClick={this.handleModalOpen}
                  type="submit"
                  className="badge badge-pill badge-primary FormsGeneral-Button"
                >
                  NEXT
                </button>
              )}

              {permit && (
                <button
                  onClick={() => {
                    history.push(previousPath);
                  }}
                  className="badge badge-pill badge-primary FormsGeneral-Button"
                >
                  <small>Return To Edit</small>
                </button>
              )}
            </Col>
          </Row>
        </PageTransition>
        <Modal isOpen={this.state.modal} centered className="confirmModal">
          <ModalBody className="p-5">
            <h2 className="mb-3">
              Are you sure want to create a news with follow publishers?
            </h2>
            <ol className="m-0 pl-5">
              {selectedPublishers.map(publisher => (
                <li key={publisher.id}>
                  <p className="text-muted">{publisher.title}</p>
                </li>
              ))}
            </ol>
          </ModalBody>
          <ModalFooter className="no-border">
            <button
              onClick={this.handleModalOpen}
              className="swal-button swal-button--cancel"
            >
              Cancel
            </button>
            <button
              onClick={this.handleModalConfirm}
              className="swal-button swal-button--confirm swal-button--danger"
            >
              Ok
            </button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login,
  selectedPublishers: state.publishers.selectedPublishers,
  loading: state.publishers.loading,
  publishersCount: state.publishers.meta.total,
  refreshLoading: state.publishers.refreshLoading,
  publishersList: state.publishers.publishers,
  permit: state.common.permit,
  previousPath: state.common.previousPath
});

export default connect(
  mapStateToProps,
  {
    getPublishers,
    refreshPublishers,
    setSelectedPublishers,
    deleteSelectedPublishers,
    setPublisherToEdit,
    deletePublisherFromEdit
  }
)(PublisherList);
