import React from "react";

import SearchBar from "../SearchBar";
import UsersTable from "../UsersTable";
import { Link } from "react-router-dom";
import PageTransition from "../PageTransition";

class UserList extends React.Component {
  constructor(props) {
    super(props);

    this.usersTableRef = React.createRef();

    this.state = {
      quantity: 0
    };
  }

  updateQuantity = quantity => {
    this.setState({
      quantity: quantity
    });
  };

  onFormSubmit = term => {
    this.usersTableRef.current && this.usersTableRef.current.searchFor(term);
  };

  render() {
    return (
      <PageTransition>
        <div className="row ml-0 mr-0 mt-5 justify-content-center" id="">
          <div className="col-12 col-sm-11 mt-5">
            <div className="row ml-0 mr-0 my-4 justify-content-between">
              <h1>ADMIN USERS</h1>
              <div>
                <Link
                  to="/users/new"
                  className="badge-pill ml-auto ListingActionButton ListingActionButton_add"
                >
                  &nbsp;add
                  <img
                    alt="arrow"
                    src="images-eve/plus-white.svg"
                    className="ListingAction-Icon ListingAction-Icon_add"
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-11">
            <div className="row m-0">
              <div className="col-12 col-sm-4 p-0">
                <SearchBar onSubmit={this.onFormSubmit} />
                <p className="search-bar__result-search">
                  Se han encontrado {this.state.quantity} elementos
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-11">
            <div className="Listing">
              <div className="table-responsive">
                <UsersTable
                  ref={this.usersTableRef}
                  updateQuantity={this.updateQuantity}
                />
              </div>
            </div>
          </div>
        </div>
      </PageTransition>
    );
  }
}

export default UserList;
