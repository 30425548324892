import React from "react";

import { Container, Row, Col } from "reactstrap";

import ArrowLeft from "../../images-eve/arrow-right.svg";
import PageTransition from "../PageTransition";
import FormUserNew from "../FormUserNew";

const UserNew = () => (
  <PageTransition>
    <Container
      className="vh100 d-flex align-items-center justify-content-center"
      fluid
    >
      <Row className="m-0 w-100" id="content">
        <Col xs={12} md={{ size: 10, offset: 1 }}>
          <Row className="align-items-center">
            <Col xs={12} sm={4}>
              <div className="TitlesColumLeft">
                <div className="TitlesColumLeft-First_top">
                  <img
                    className="TitlesColumLeft-First_top__arrow"
                    src={ArrowLeft}
                    alt=""
                  />
                  profile
                </div>
                <h1 className="TitlesColumLeft-First">
                  <span className="TitlesColumLeft-First--Down">NEW</span>
                  USER
                </h1>
                <h2 className="TitlesColumLeft-Second">Welcome to the crew!</h2>
                <h4 className="TitlesColumLeft-Third">
                  Complete the user information.
                </h4>
              </div>
            </Col>
            <Col
              xs={12}
              sm={{ size: 6, offset: 1 }}
              lg={{ size: 6, offset: 1 }}
              xl={{ size: 6, offset: 1 }}
            >
              <FormUserNew />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </PageTransition>
);

export default UserNew;
