import React, { Component } from "react";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import EditIcon from "../../images-eve/edit-popover_listing.svg";

class CategoriesTable extends Component {
  state = {
    categories: []
  };

  componentDidMount() {
    const { categories } = this.props;

    if (categories.length > 0) {
      const parsedCategories = categories.map(category => ({
        id: category.id,
        image: (
          <img src={category.image} alt="category" className="category_image" />
        ),
        name: category.name,
        options: (
          <Link
            to={`/categories/${category.id}/edit`}
            className="table-actions-link"
          >
            <img
              src={EditIcon}
              alt="edit icon"
              className="Listing__view-icon"
            />
            EDIT
          </Link>
        )
      }));

      this.setState({
        categories: [...parsedCategories]
      });
    }
  }

  columns = [
    {
      dataField: "image",
      text: "IMAGE",
      headerClasses: (column, colIndex) => "category-header_table--image"
    },
    {
      dataField: "name",
      text: "NAME",
      headerClasses: (column, colIndex) => "category-header_table--name"
    },
    {
      dataField: "options",
      text: "ACTIONS"
    }
  ];

  noDataIndication = () => {
    return <span>No information</span>;
  };

  render() {
    const { categories } = this.state;

    return (
      <div className="Listing">
        <div className="table-responsive" style={{ overflow: "hidden" }}>
          <BootstrapTable
            classes="table listing__table"
            remote={{
              pagination: true,
              sort: true,
              filter: true
            }}
            bordered={false}
            onTableChange={(type, fields) => null}
            keyField="id"
            columns={this.columns}
            noDataIndication={this.noDataIndication}
            data={categories}
          />
        </div>
      </div>
    );
  }
}

export default CategoriesTable;
