import React, { Component } from "react";
import moment from "moment";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { connect } from "react-redux";
import { deleteNews } from "../../actions/newsSingle";
import { getNewsList } from "../../actions/news";

import ArrowListingTop from "../../images-eve/arrow-listing-top.svg";
import ArrowListingDown from "../../images-eve/arrow-listing.svg";
import EditIcon from "../../images-eve/edit-popover_listing.svg";
import DeleteIcon from "../../images-eve/delete_listing.svg";
import ImageDots from "../../images-eve/dots-horizontal.svg";

class NewsTable extends Component {
  state = {
    news: [],
    tableSpinner: false,
    modal: false,
    submitting: false,
    itemToDelete: null
  };

  static getDerivedStateFromProps(props, state) {
    if (props.loading !== state.tableSpinner) {
      return {
        tableSpinner: props.loading
      };
    }

    return null;
  }

  toggle = id => {
    const { deleteNewsToPublishOnDelete } = this.props;
    deleteNewsToPublishOnDelete();
    this.setState({
      modal: !this.state.modal,
      itemToDelete: id ? id : null
    });
  };

  handleDelete = async () => {
    const { itemToDelete } = this.state;
    const { deleteNews, getNewsList } = this.props;

    this.setState({
      submitting: true
    });

    await deleteNews(itemToDelete);

    this.setState({
      modal: false,
      submitting: false,
      itemToDelete: null
    });

    await getNewsList(1, { sort: "-created_at" });
  };

  componentDidUpdate(prevProps, prevState) {
    const { newsList, setNewsToPublish, deleteNewsToPublish } = this.props;

    if (prevState.tableSpinner) {
      const parsedNewsList = newsList.map(news => ({
        checkbox: (
          <div
            className={`roundedOne ${
              news.status === "published" ? "is-published" : ""
            }`}
          >
            <input
              type="checkbox"
              id={news.id}
              name={news.id}
              className={`${news.status === "published" ? "is-published" : ""}`}
              disabled={news.status === "published" ? true : false}
              onChange={e => {
                if (e.target.checked) {
                  setNewsToPublish({ id: news.id, title: news.title });
                } else {
                  deleteNewsToPublish(news.id);
                }
              }}
            />
            <label htmlFor={news.id} />
          </div>
        ),
        id: news.id,
        title: news.title,
        type:
          news.ad === null ? (
            <span className="text-success">NEWS</span>
          ) : (
            <span className="text-warning">AD</span>
          ),
        categories: news.categories
          .map(category => {
            return category.name;
          })
          .join(", "),
        created_at: moment(news.starts_at).format("LLL"),
        status:
          news.status === "published" ? (
            <span className="text-success">{news.status.toUpperCase()}</span>
          ) : (
            <span className="text-secondary">{news.status.toUpperCase()}</span>
          ),
        impressions: news.statistics.impressions,
        clicks: news.statistics.clicks,
        options:
          news.status !== "published" ? (
            <UncontrolledDropdown>
              <DropdownToggle className="btn-actions">
                <img
                  src={ImageDots}
                  alt="dots"
                  className="d-block img-fluid mx-auto"
                />
              </DropdownToggle>
              <DropdownMenu right className="p-0">
                <DropdownItem
                  onClick={() =>
                    this.props.history.push(`/news/${news.id}/edit`)
                  }
                  className="py-3"
                >
                  <img
                    src={EditIcon}
                    alt="edit icon"
                    className="Listing__view-icon"
                  />
                  EDIT
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.toggle(news.id)}
                  className="py-3"
                >
                  <img
                    src={DeleteIcon}
                    alt="delete icon"
                    className="Listing__view-icon"
                  />
                  DELETE
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
            ""
          )
      }));

      this.setState({
        news: [...parsedNewsList]
      });
    }
  }

  handleFieldOrder = async (field, order) => {
    const { handleSortByField } = this.props;
    handleSortByField(field, order);
  };

  sortCaret = (order, column) => {
    if (order === "asc") {
      return (
        <img src={ArrowListingTop} alt="" className="Listing__arrow-order" />
      );
    }

    if (order === "desc") {
      return (
        <img src={ArrowListingDown} alt="" className="Listing__arrow-order" />
      );
    }

    return null;
  };

  noDataIndication = () => {
    if (this.state.tableSpinner) return null;

    return <span>No information</span>;
  };

  paginationOptions = {
    page: null,
    paginationSize: null,
    totalSize: null,
    pageStartIndex: 1,
    hideSizePerPage: true,
    onPageChange: (page, sizePerPage) => {
      const { handlePaginator } = this.props;
      handlePaginator(page);
    }
  };

  // Columns
  columns = [
    {
      dataField: "checkbox",
      text: "CHECKBOX",
      headerStyle: {
        opacity: "0"
      }
    },
    {
      dataField: "title",
      text: (
        <span>
          <img
            src="images-eve/icon-note.svg"
            alt=""
            className="Listing__view-icon Listing__view-icon_vertical"
          />
          TITLE NOTE
        </span>
      ),
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret
    },
    {
      dataField: "type",
      text: "TYPE",
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret
    },
    {
      dataField: "categories",
      width: "200px",
      text: "CATEGORIES",
      headerClasses: (column, colIndex) => "title-news__max-width"
    },
    {
      dataField: "created_at",
      width: "200px",
      text: (
        <span>
          <img
            src="images-eve/icon-date.svg"
            alt=""
            className="Listing__view-icon"
          />
          START AT
        </span>
      ),
      sort: true,
      onSort: this.handleFieldOrder,
      sortCaret: this.sortCaret,
      headerClasses: (column, colIndex) => "title-news__max-width"
    },
    {
      dataField: "status",
      headerClasses: (column, colIndex) => "title-news__max-width",
      text: "STATUS"
    },
    {
      dataField: "impressions",
      headerClasses: (column, colIndex) => "title-news__max-width",
      text: "IMPRESSIONS"
    },
    {
      dataField: "clicks",
      headerClasses: (column, colIndex) => "title-news__max-width",
      text: "CLICKS"
    },
    {
      dataField: "options",
      text: "ACTIONS"
    }
  ];

  render() {
    const { news } = this.state;

    const pagination = {
      ...this.paginationOptions,
      page: this.props.currentPage,
      paginationSize: this.props.paginationSize,
      totalSize: this.props.totalSize
    };

    return (
      <div className="Listing">
        <div className="table-responsive" style={{ overflow: "hidden" }}>
          <BootstrapTable
            classes="table listing__table"
            remote={{
              pagination: true,
              sort: true,
              filter: true
            }}
            bordered={false}
            onTableChange={(type, fields) => null}
            keyField="id"
            columns={this.columns}
            noDataIndication={this.noDataIndication}
            data={news}
            pagination={paginationFactory(pagination)}
          />
        </div>

        <Modal isOpen={this.state.modal} centered className="confirmModal">
          <ModalBody className="p-5">
            <h2 className="mb-3">
              Are you sure want to publish the following news?
            </h2>
          </ModalBody>

          <ModalFooter className="no-border">
            {!this.state.submitting && (
              <button
                onClick={() => this.toggle()}
                className="swal-button swal-button--cancel"
              >
                Cancel
              </button>
            )}

            <button
              onClick={this.handleDelete}
              className="swal-button swal-button--confirm swal-button--danger"
              disabled={this.state.submitting ? true : false}
            >
              {this.state.submitting ? (
                <Spinner size="sm" color="light" />
              ) : (
                "Ok"
              )}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentPage: state.news.meta.currentPage,
  paginationSize: state.news.meta.per_page,
  totalSize: state.news.meta.total
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      deleteNews,
      getNewsList
    }
  )(NewsTable)
);
