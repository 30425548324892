import { UserAPI } from "../middlewares/api";

export const tryLogin = (email, password) => async dispatch => {
  return new Promise((resolve, reject) => {
    UserAPI.login(email, password)
      .then(response => {
        dispatch(loginSuccess(response.data.access_token, response.data.user));
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const logout = () => {
  return {
    type: "REMOVE_USER"
  };
};

export const loginSuccess = (token = null, user = null) => {
  return {
    type: "STORE_USER",
    payload: {
      authToken: token,
      user: user
    }
  };
};

export const forgotPassword = email => async dispatch => {
  return new Promise((resolve, reject) => {
    UserAPI.forgotPassword(email)
      .then(response => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const passwordRecovery = (password, token) => async dispatch => {
  return new Promise((resolve, reject) => {
    UserAPI.passwordRecovery(password, token)
      .then(response => {
        resolve();
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const editProfile = body => async (dispatch, getState) => {
  const authToken = getState().login.authToken;
  return new Promise((resolve, reject) => {
    UserAPI.editProfile(authToken, body)
      .then(response => {
        dispatch(storeProfile(response.data.user));
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const editAvatar = body => async (dispatch, getState) => {
  const authToken = getState().login.authToken;
  return new Promise((resolve, reject) => {
    UserAPI.editAvatar(authToken, body)
      .then(response => {
        dispatch(storeProfile(response.data.user));
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const storeProfile = user => {
  return {
    type: "STORE_PROFILE",
    payload: user
  };
};
