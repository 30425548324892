import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";

import { connect } from "react-redux";
import { getCategory } from "../../actions/categories";

import PageTransition from "../PageTransition";
import CategoryForm from "./CategoryForm";

class CategoryEdit extends Component {
  async componentDidMount() {
    const { getCategory, history } = this.props;
    const { id } = this.props.match.params;

    const response = await getCategory(id);

    if (response === 404) {
      history.push("/categories");
    }
  }

  render() {
    const { id } = this.props.match.params;

    return (
      <PageTransition>
        <Container
          className="vh100 d-flex align-items-center justify-content-center"
          fluid
        >
          <Row className="w-100">
            <Col xs={12} md={{ size: 10, offset: 1 }}>
              <Row className="align-items-center">
                <Col xs={12} sm={4}>
                  <div className="TitlesColumLeft">
                    <div className="TitlesColumLeft-First_top">
                      <img
                        className="TitlesColumLeft-First_top__arrow"
                        src="/static/media/arrow-right.e7612674.svg"
                        alt=""
                      />
                      categories
                    </div>
                    <h1 className="TitlesColumLeft-First">
                      <span className="TitlesColumLeft-First--Down">
                        CATEGORY
                      </span>
                      EDIT
                    </h1>
                    <h4 className="TitlesColumLeft-Third">
                      Complete and save changes
                    </h4>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={{ size: 6, offset: 1 }}
                  lg={{ size: 6, offset: 1 }}
                  xl={{ size: 6, offset: 1 }}
                >
                  <CategoryForm id={id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </PageTransition>
    );
  }
}

export default connect(
  null,
  {
    getCategory
  }
)(CategoryEdit);
