import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import App from "./components/App";
import createStore from "./store/createStore";

import "react-day-picker/lib/style.css";
import "./assets/css/paginator.css";
import "./assets/css/custom.css";

const store = createStore();

ReactDOM.render(
  <Provider store={store.store}>
    <PersistGate loading={null} persistor={store.persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.querySelector("#app")
);
