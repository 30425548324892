import React from 'react';
import PropTypes from 'prop-types';

class DropdownMenu extends React.Component {
    state = {};

    render() {
        return (
            <div className="btn-group">
                <button type="button" className="btn dropdown-toggle dropdown__general_caret"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {this.props.button}
                </button>
                <div className="dropdown-menu dropdown-menu-right">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

DropdownMenu.propTypes = {
    button: PropTypes.object.isRequired
};

export default DropdownMenu;
