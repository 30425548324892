export const loginReducer = (state = null, action) => {
  switch (action.type) {
    case "STORE_USER":
      return {
        ...state,
        user: action.payload.user,
        authToken: action.payload.authToken
      };
    case "REMOVE_USER":
      return null;
    case "STORE_PROFILE":
      return {
        ...state,
        user: action.payload
      };
    case "RESET_LOGIN":
      return null;
    default:
      return state;
  }
};
