import { PublisherAPI } from "../middlewares/api";
import { alertError, alertSuccess } from "../helpers/alerts";

export const getPublishers = (page = 1, filters) => async (
  dispatch,
  getState
) => {
  dispatch({ type: "GET_PUBLISHERS_LOADING" });

  if (getState().login) {
    const authToken = getState().login.authToken;

    try {
      const response = await PublisherAPI.publisherList(
        authToken,
        page,
        filters
      );

      dispatch({
        type: "GET_PUBLISHERS_SUCCESS",
        payload: {
          publishers: response.data.data,
          links: {
            first: response.data.links.first,
            last: response.data.links.last,
            next: response.data.links.next,
            prev: response.data.links.prev
          },
          meta: {
            currentPage: response.data.meta.current_page,
            from: response.data.meta.from,
            last_page: response.data.meta.last_page,
            per_page: response.data.meta.per_page,
            to: response.data.meta.to,
            total: response.data.meta.total
          }
        }
      });
    } catch (error) {
      dispatch({ type: "GET_PUBLISHERS_ERROR" });
      alertError("Something bad happened.");
    }
  }
};

export const refreshPublishers = () => async (dispatch, getState) => {
  const authToken = getState().login.authToken;
  dispatch({ type: "REFRESH_LOADING" });
  try {
    await PublisherAPI.refreshPublishers(authToken);
    dispatch({ type: "REFRESH_SUCCESS" });
    alertSuccess("Publishers has been refreshed successfully");
  } catch (error) {
    dispatch({ type: "REFRESH_ERROR" });
    alertError("Something bad happened.");
  }
};

export const getPublisher = id => async (dispatch, getState) => {
  const authToken = getState().login.authToken;
  return new Promise((resolve, reject) => {
    PublisherAPI.getPublisher(authToken, id)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const setSelectedPublishers = item => dispatch => {
  dispatch({
    type: "SET_SELECTED_PUBLISHERS",
    payload: {
      data: item
    }
  });

  const apiNewsNews = {
    id: item.id,
    body: [],
    link: item.link,
    title: item.title,
    portal: item.portal
  };

  dispatch({
    type: "SET_API_NEWS",
    payload: {
      value: apiNewsNews
    }
  });
};

export const deleteSelectedPublishers = id => dispatch => {
  dispatch({
    type: "DELETE_SELECTED_PUBLISHERS",
    payload: {
      data: id
    }
  });

  dispatch({
    type: "DELETE_API_NEWS",
    payload: {
      value: id
    }
  });
};

export const deleteSelectedPublishersFromNews = id => dispatch => {
  dispatch({
    type: "DELETE_PUBLISHERS_FROM_NEWS",
    payload: {
      id
    }
  });
};
