import React from "react";
import { Link } from "react-router-dom";

class DropdownLink extends React.Component {
  state = {};

  render() {
    return (
      <Link
        to={this.props.to}
        className="dropdown-item dropdown-item__general"
        type="button"
      >
        <img src={this.props.image} className="icons-popover" alt="" />
        {this.props.label}
      </Link>
    );
  }
}

export default DropdownLink;
