import React from 'react';

class InterestLabel extends React.Component {
    state = {};

    render() {
        return (
            <span className="listing__table__tags-interests">{this.props.label}</span>
        )
    }
}

export default InterestLabel;
