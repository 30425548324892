import React from 'react';
import ClientLogoImage from "../images-eve/client-logo.svg";
import * as PropTypes from "prop-types";

class UploadImageProfile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            imagePreview: this.props.imagePreview,

        };
        this.inputRef = React.createRef();
    }

    onClick = event => {
          this.inputRef.current.click();
    };

    onInputChange = event => {
        this.setState({
            imagePreview: URL.createObjectURL(event.target.files[0]),
        });

        this.props.onImageChange(event.target.files[0]);
    };

    getFile() {
        return this.inputRef.current.files[0];
    }

    renderBackground() {
        if(this.state.imagePreview)
            return (
                <div onClick={this.onClick} className="UpLoadImageProfile" style={{ backgroundImage: `url(${this.state.imagePreview})`, backgroundPosition: 'center center' }}>
                    <div className="UpLoadImageProfileHover-Label--Hover">Change Image</div>
                </div>
            );
        else
            return (
                <div onClick={this.onClick} className="UpLoadImageProfile">
                    <img alt='dropdownimage' className="UpLoadImageProfile-Img" src={ClientLogoImage} />
                    <span className="UpLoadImageProfile-Label">Image</span>
                </div>
            );
    }

    renderErrors() {
        if(!this.props.errors)
            return null;
        return this.props.errors.map(error => {
            return <span className="help-block text-danger input-errors">{error}</span>
        })
    }

    render() {
        return (
            <div className='container__image-and-error'>
                {this.renderBackground()}
                <input onChange={this.onInputChange} ref={this.inputRef} type="file" style={{display: 'none'}}/>
                <div className="container__msj-error__image">
                    {this.renderErrors()}
                </div>
            </div>
        )
    }
}

UploadImageProfile.propTypes = {
    imagePreview: PropTypes.string,
    onImageChange: PropTypes.func
};

export default UploadImageProfile;
