import React, { useState } from "react";
import { Input, Form, InputGroup, InputGroupAddon } from "reactstrap";
import PropTypes from "prop-types";

const SearchBar = ({ onSubmit }) => {
  const [text, setText] = useState("");
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(text);
  };

  return (
    <Form onSubmit={handleSubmit} className="w-100 mr-3">
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <span
            className="input-group-text listing__input-search listing__input-search_icon"
            id="basic-addon1"
          >
            <img
              src="images-eve/search-icon.svg"
              alt="search icon"
              className="Listing__view-icon"
            />
          </span>
        </InputGroupAddon>
        <Input
          name="text"
          type="text"
          className="form-control listing__input-search listing__input-search_left"
          placeholder="SEARCH KEYWORD"
          value={text}
          onChange={e => setText(e.target.value)}
        />
      </InputGroup>
    </Form>
  );
};

SearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default SearchBar;
