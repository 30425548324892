import { AddressAPI } from "../middlewares/api";

export const getProvinces = () => async dispatch => {
  dispatch({ type: "PROVINCES_LOADING" });

  const response = await AddressAPI.provincesList();

  dispatch({
    type: "PROVINCES_SUCCESS",
    payload: {
      provinces: response.data.provinces
    }
  });
};

export const getLocations = () => async dispatch => {
  dispatch({ type: "LOCATIONS_LOADING" });
  const response = await AddressAPI.locationsList();
  dispatch({
    type: "LOCATIONS_SUCCESS",
    payload: {
      locations: response.data.locations
    }
  });
};
