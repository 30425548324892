import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Input, Label, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import { setNewsTitle, setNewsPriority, setCategories } from "../actions/news";

import { deleteSelectedPublishers } from "../actions/publishers";

import CategoryFilter from "./CategoryFilter";
import InputField from "./InputField";
import PublisherInfoBox from "./PublisherInfoBox";
import AddArticleImage from "../images-eve/add_article.svg";

const NewsNewOne = ({
  selectedCategories,
  newsTitle,
  setNewsTitle,
  newsPriority,
  setNewsPriority,
  selectedPublishers,
  deleteSelectedPublishers,
  setCategories
}) => (
  <Col md="4" className="mt-5">
    <Row className="m-0">
      <Col className="p-0 my-4">
        <Row className="m-0 align-items-center">
          <Col xs="3">
            <Label for="categories" className="news-new-font text-capitalize">
              category:
            </Label>
          </Col>
          <Col xs="9">
            <CategoryFilter
              selectedValues={selectedCategories}
              setCategories={setCategories}
            />
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="p-0 my-4">
        <Row className="m-0 align-items-center">
          <Col xs="3">
            <Label for="title" className="news-new-font text-capitalize">
              title:
            </Label>
          </Col>
          <Col xs="9">
            <InputField
              id="title"
              name="title"
              type="text"
              value={newsTitle}
              className="article__input-title"
              onChange={e => setNewsTitle(e.target.value)}
            />
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="my-4 p-0">
        <Row className="m-0 align-items-center">
          <Col xs="3">
            <Label for="priority" className="news-new-font text-capitalize">
              priority
            </Label>
          </Col>
          <Col xs="9">
            <Input
              id="priority"
              type="select"
              onChange={e => setNewsPriority(e.target.value)}
              defaultValue={newsPriority || "1"}
            >
              <option value="1">Normal</option>
              <option value="2">Importante</option>
              <option value="3">Urgente</option>
            </Input>
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="p-0 my-4">
        <Row className="m-0 align-items-center">
          <Col xs="6">
            <Label className="article__items news-new-font text-capitalize">
              news selected:
            </Label>
            <span className="ml-3">{selectedPublishers.length}</span>
          </Col>
          <Col xs="6" className="text-right">
            <Link
              to="/"
              className="article__items d-flex align-items-center justify-content-end"
            >
              <img
                className="rounded-circle Header-Container__user-image mr-2"
                src={AddArticleImage}
                alt="add"
              />
              <span className="news-new-font text-capitalize">add article</span>
            </Link>
          </Col>
        </Row>
      </Col>

      <Col xs="12" className="p-0">
        {selectedPublishers.map(publisher => (
          <PublisherInfoBox
            key={publisher.id}
            publisher={publisher}
            onDelete={id => {
              deleteSelectedPublishers(id);
            }}
          />
        ))}
      </Col>
    </Row>
  </Col>
);
NewsNewOne.propTypes = {
  deleteSelectedPublishers: PropTypes.func.isRequired,
  newsPriority: PropTypes.number,
  newsTitle: PropTypes.string.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  selectedPublishers: PropTypes.array.isRequired,
  setNewsPriority: PropTypes.func.isRequired,
  setNewsTitle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  selectedPublishers: state.publishers.selectedPublishers,
  selectedCategories: state.news.news.categories,
  newsTitle: state.news.news.title,
  newsPriority: state.news.news.priority
});

export default connect(
  mapStateToProps,
  {
    deleteSelectedPublishers,
    setNewsTitle,
    setNewsPriority,
    setCategories
  }
)(NewsNewOne);
