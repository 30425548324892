import React from "react";

import { FormGroup, Col } from "reactstrap";

const TabsNavigation = ({
  setTab,
  tab,
  saveAndPublish,
  save,
  saveText,
  publishText
}) => {
  return (
    <Col>
      <FormGroup className="my-5 text-center">
        {tab === "1" && (
          <button
            type="button"
            className="ListingActionButton_orange mx-2"
            onClick={() => setTab("2")}
          >
            Next
          </button>
        )}
        {tab === "2" && (
          <>
            <button
              type="button"
              className="FormsGeneral-Button FormsGeneral-Button_sm mx-2"
              onClick={() => setTab("1")}
            >
              Back
            </button>
            <button
              type="button"
              className="listingActionsBtn mx-2"
              onClick={() => save()}
            >
              {saveText}
            </button>
            <button
              type="button"
              className="ListingActionButton_orange mx-2"
              onClick={() => saveAndPublish()}
            >
              {publishText}
            </button>
          </>
        )}
      </FormGroup>
    </Col>
  );
};

export default TabsNavigation;
