const initialState = {
  categories: {
    loading: false,
    data: [],
    category: {}
  },
  age_range: [],
  globalStyle: "",
  previousPath: "",
  permit: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOADING_CATEGORIES": {
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: true
        }
      };
    }

    case "GET_CATEGORIES_SUCCESS": {
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          data: [...action.payload.categories]
        }
      };
    }

    case "GET_CATEGORIES_FAILURE": {
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false
        }
      };
    }

    case "GET_SINGLE_CATEGORY_SUCCESS": {
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false,
          category: {
            ...state.categories.category,
            ...action.payload.category
          }
        }
      };
    }

    case "GET_SINGLE_CATEGORY_FAILURE": {
      return {
        ...state,
        categories: {
          ...state.categories,
          loading: false
        }
      };
    }

    case "SET_WRAPPER_CLASS": {
      return { ...state, globalStyle: action.payload };
    }

    case "GET_AGE_RANGES": {
      return {
        ...state,
        age_range: [...action.payload.value]
      };
    }

    case "SET_PREVIOUS_PATH": {
      return {
        ...state,
        previousPath: action.payload.path,
        permit: action.payload.permit
      };
    }

    case "DELETE_PREVIOUS_PATH": {
      return {
        ...state,
        previousPath: "",
        permit: action.payload.permit
      };
    }

    case "RESET_EDITING": {
      return {
        ...state,
        previousPath: "",
        permit: false
      };
    }

    default:
      return state;
  }
};
