import React from "react";
import {
  Input,
  Form,
  FormGroup,
  FormText,
  FormFeedback,
  Spinner
} from "reactstrap";
import { alertError, alertSuccess } from "../helpers/alerts";

import { Formik } from "formik";
import * as Yup from "yup";

import { editProfile, editAvatar } from "../actions/login";
import { connect } from "react-redux";

import UploadImageProfile from "./UploadImageProfile";
import SubmitButton from "./SubmitButton";

class FormProfile extends React.Component {
  state = {
    avatarLoading: false
  };

  avatarLoading = () => {
    this.setState({
      avatarLoading: !this.state.avatarLoading
    });
  };

  handleImageUpload = async file => {
    const { editAvatar } = this.props;
    this.avatarLoading();
    const formData = new FormData();
    formData.append("avatar", file ? file : null);

    try {
      const response = await editAvatar(formData);
      if (response && response.status === 200) {
        alertSuccess("Your avatar was uploaded successfully");
      }
    } catch (error) {
      if (error.response.status === 422) {
        alertError(error.response.message);
      } else {
        alertError(error.response.message);
      }
    }

    this.avatarLoading();
  };

  render() {
    const { name, email, avatar } = this.props.login.user;
    const { avatarLoading } = this.state;

    return (
      <>
        <div className="p-relative">
          {avatarLoading && (
            <div className="loading-avatar">
              <Spinner type="grow" color="dark" />
            </div>
          )}
          <UploadImageProfile
            onImageChange={this.handleImageUpload}
            imagePreview={avatar}
          />
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            name: name || "",
            email: email || ""
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Required."),
            email: Yup.string()
              .email()
              .required("Required.")
          })}
          onSubmit={async (values, { setSubmitting }) => {
            const { editProfile } = this.props;

            const response = await editProfile(values);

            if (response && response.status === 200) {
              setSubmitting(false);
              alertSuccess("Your profile was saved successfully");
            }
          }}
          render={({
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <fieldset
                disabled={isSubmitting}
                aria-busy={isSubmitting}
                className="FormsGeneral"
              >
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="NAME* "
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="FormsGeneral-Inputs"
                  />
                  {errors.name && touched.name && (
                    <FormFeedback className="d-block">
                      {errors.name}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <Input
                    name="email"
                    type="email"
                    id="email"
                    placeholder="EMAIL*"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="FormsGeneral-Inputs"
                  />
                  {errors.email && touched.email && (
                    <FormFeedback className="d-block">
                      {errors.email}
                    </FormFeedback>
                  )}
                </FormGroup>
                <FormGroup>
                  <FormText color="muted">Required Fields *</FormText>
                </FormGroup>
                <FormGroup>
                  <SubmitButton
                    className="badge badge-pill badge-primary FormsGeneral-Button"
                    disabled={isSubmitting}
                  >
                    SAVE
                  </SubmitButton>
                </FormGroup>
              </fieldset>
            </Form>
          )}
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  login: state.login
});

export default connect(
  mapStateToProps,
  {
    editProfile,
    editAvatar
  }
)(FormProfile);
