import {AppUserAPI} from "../middlewares/api";

export const getAppUsers = query => async (dispatch, getState) => {
    const authToken = getState().login.authToken;
    return new Promise((resolve, reject) => {
        AppUserAPI.appUserList(authToken, query).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};
