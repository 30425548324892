import React from "react";
import { Container, Row, Col } from "reactstrap";
import FormPasswordRecovery from "./FormPasswordRecovery";
import PageTransition from "../PageTransition";

const PasswordRecovery = ({ match }) => {
  return (
    <div className="h-100-no-navbar d-flex align-items-center">
      <div className="w-100">
        <PageTransition>
          <Container>
            <Row className="ml-0 mr-0 aling-items-center">
              <Col xs={12} sm={4} md={5}>
                <div className="TitlesColumLeft">
                  <div className="TitlesColumLeft-First_top">New</div>
                  <h1 className="TitlesColumLeft-First">
                    <span className="TitlesColumLeft-First--Down">PASS-</span>
                    WORD?
                  </h1>
                  <h2 className="TitlesColumLeft-Second">
                    Now it’s time to update your password...{" "}
                  </h2>
                  <h4 className="TitlesColumLeft-Third">
                    Complete your user information.
                  </h4>
                </div>
              </Col>
              <Col xs={12} md={7} className="d-flex align-items-center">
                <FormPasswordRecovery token={match.params.token} />
              </Col>
            </Row>
          </Container>
        </PageTransition>
      </div>
    </div>
  );
};

export default PasswordRecovery;
