import React from "react";
import styled from "styled-components";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "../assets/css/datetime.css";
import "react-datepicker/dist/react-datepicker.css";

import { connect } from "react-redux";
import {
  setNewsFrequency,
  setNewsGender,
  setStartAt,
  setEndsAt,
  setAgeRange
} from "../actions/news";

const gendersOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" }
];

const DatePickerWrapper = styled.div`
  .react-datepicker__input-container {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

class NewsNewThree extends React.Component {
  handleChangeStart = date => {
    const { setStartAt } = this.props;
    setStartAt(date);
  };

  handleChangeEnds = date => {
    const { setEndsAt } = this.props;
    setEndsAt(date);
  };

  render() {
    const {
      newsFrequency,
      setNewsFrequency,
      setNewsGender,
      genders,
      startsAt,
      endsAt,
      ageRange,
      setAgeRange,
      ageRangeId
    } = this.props;

    const selectedGenders = genders.map(item => ({
      value: item,
      label: item.charAt(0).toUpperCase() + item.slice(1)
    }));

    const newStartsAt = new Date(startsAt);
    const newEndsAt = new Date(endsAt);

    return (
      <Row>
        <Col>
          <FormGroup className="my-4">
            <Row form>
              <Col xs={5}>
                <Label
                  for="hour"
                  className="reate-news__titles-news create-news__titles-preview text-uppercase"
                >
                  Starts At
                </Label>
              </Col>
              <Col xs={7}>
                <DatePickerWrapper>
                  <DatePicker
                    className="form-control"
                    selected={newStartsAt}
                    selectsStart
                    showTimeSelect
                    startDate={newStartsAt}
                    endDate={newEndsAt}
                    onChange={this.handleChangeStart}
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </DatePickerWrapper>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="my-4">
            <Row form>
              <Col xs={5}>
                <Label
                  for="hour"
                  className="reate-news__titles-news create-news__titles-preview text-uppercase"
                >
                  Ends At
                </Label>
              </Col>
              <Col xs={7}>
                <DatePickerWrapper>
                  <DatePicker
                    className="form-control"
                    selected={newEndsAt}
                    selectsEnd
                    showTimeSelect
                    startDate={newStartsAt}
                    endDate={newEndsAt}
                    onChange={this.handleChangeEnds}
                    timeIntervals={15}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={newStartsAt}
                  />
                </DatePickerWrapper>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup className="my-4">
            <Row form className="align-items-center">
              <Col xs={5}>
                <Label
                  for="frequency"
                  className="reate-news__titles-news create-news__titles-preview text-uppercase"
                >
                  Frequency
                </Label>
              </Col>
              <Col xs={7}>
                <Input
                  id="frequency"
                  type="number"
                  onChange={e => setNewsFrequency(e.target.value)}
                  value={newsFrequency}
                  max="99"
                  min="1"
                  disabled={true}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="my-4">
            <Row form className="align-items-center">
              <Col xs={5}>
                <Label
                  for="age_range"
                  className="reate-news__titles-news create-news__titles-preview text-uppercase"
                >
                  Age Range
                </Label>
              </Col>
              <Col xs={7}>
                <Select
                  value={ageRangeId.map(item =>
                    ageRange.find(el => (el.value === item ? item : null))
                  )}
                  options={ageRange}
                  onChange={value => setAgeRange(value)}
                  isMulti
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup className="my-4">
            <Row form className="align-items-center">
              <Col xs={5}>
                <Label
                  for="Gender"
                  className="reate-news__titles-news create-news__titles-preview text-uppercase"
                >
                  Gender
                </Label>
              </Col>
              <Col xs={7}>
                <Select
                  value={selectedGenders}
                  options={gendersOptions}
                  onChange={selectedOption => setNewsGender(selectedOption)}
                  isMulti
                />
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  newsFrequency: state.news.news.frequency,
  provinces: state.addresses.provinces.data,
  loadingProvinces: state.addresses.provinces.loading,
  locations: state.addresses.locations.data,
  loadingLocations: state.addresses.locations.loading,
  genders: state.news.news.genders,
  startsAt: state.news.news.starts_at,
  endsAt: state.news.news.ends_at,
  ageRange: state.common.age_range,
  ageRangeId: state.news.news.age_range
});

export default connect(
  mapStateToProps,
  {
    setNewsFrequency,
    setNewsGender,
    setStartAt,
    setEndsAt,
    setAgeRange
  }
)(NewsNewThree);
