import React, { useState } from "react";
import { Collapse, CardBody, Row, Col } from "reactstrap";
import styled from "styled-components";

import UserImage from "../images-eve/user-image.svg";
import IconArticleArrowCollapse from "../images-eve/icons-article-arrow-collapsed.svg";
import IconArticleClose from "../images-eve/icons-article-close.svg";

const BtnActions = styled.button`
  background-color: transparent;
  border: none;
`;

const PublisherInfoBox = ({ publisher, onDelete }) => {
  const [collapse, setCollapse] = useState(false);
  const [body] = useState(publisher.body);

  const handleClickDelete = () => {
    onDelete(publisher.id);
  };

  return (
    <Col xs="12" className="p-0 dropdown__articles-selected my-3">
      <Row className="m-0">
        <Col xs="8" className="d-flex align-items-center">
          <img
            className="rounded-circle article__user-image"
            src={UserImage}
            alt="portal"
          />
          <p className="article__title m-0 px-2">{publisher.title}</p>
        </Col>
        <Col xs="4" className="text-right">
          <BtnActions type="button" onClick={() => setCollapse(!collapse)}>
            <img
              className="article__icons arrow__change"
              src={IconArticleArrowCollapse}
              style={{
                transform: `rotate(${collapse ? "0deg" : "180deg"})`
              }}
              alt="toggle"
            />
          </BtnActions>
          <BtnActions type="button" onClick={handleClickDelete}>
            <img
              className="article__icons"
              src={IconArticleClose}
              alt="delete"
            />
          </BtnActions>
        </Col>
      </Row>
      <Collapse isOpen={collapse}>
        <CardBody>
          <Row className="m-0">
            <Col xs="12" className="p-0">
              <Row className="m-0 align-items-center">
                <Col xs="6" className="p-0">
                  <span className="d-inline-block article__source">
                    SOURCE:
                  </span>
                  <a
                    className="d-inline-block article__source-link article__source article__source_link"
                    href={publisher.link}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    {publisher.portal}
                  </a>
                </Col>
              </Row>
            </Col>
            <Col xs="12" className="p-0">
              <hr className="article__separation" />
            </Col>
            <Col xs="12" className="p-0 mt-3">
              <h4 className="article__body-title">{publisher.title}</h4>
              {publisher.body && (
                <h5 className="article__body-subtitle">{body}</h5>
              )}
              {publisher.headline && (
                <p className="article__body-description">
                  {publisher.headline}
                </p>
              )}
            </Col>
          </Row>
        </CardBody>
      </Collapse>
    </Col>
  );
};

export default PublisherInfoBox;
