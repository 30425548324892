import React from "react";

const SubmitButton = props => {
  return (
    <button type="submit" {...props}>
      {props.disabled ? (
        <span
          className="spinner-border update-btn__loading"
          role="status"
          aria-hidden="true"
        />
      ) : (
        <span>{props.children}</span>
      )}
    </button>
  );
};

export default SubmitButton;
