import React from "react";
import { Row, Col, Container } from "reactstrap";

import ArrowLeft from "../../images-eve/arrow-right.svg";
import FormProfile from "../FormProfile";
import PageTransition from "../PageTransition";

const Profile = () => (
  <PageTransition>
    <Container
      className="vh100 d-flex align-items-center justify-content-center"
      fluid
    >
      <Row className="m-0 w-100" id="content">
        <Col xs={12} md={{ size: 10, offset: 1 }}>
          <Row className="align-items-center">
            <Col xs={12} sm={4}>
              <div className="TitlesColumLeft">
                <div className="TitlesColumLeft-First_top">
                  <img
                    className="TitlesColumLeft-First_top__arrow"
                    src={ArrowLeft}
                    alt=""
                  />
                  profile
                </div>
                <h1 className="TitlesColumLeft-First">
                  <span className="TitlesColumLeft-First--Down">USER</span>
                  EDIT
                </h1>
                <h2 className="TitlesColumLeft-Second">
                  Edit your profile information
                </h2>
                <h4 className="TitlesColumLeft-Third">
                  Complete and save changes
                </h4>
              </div>
            </Col>
            <Col
              xs={12}
              sm={{ size: 6, offset: 1 }}
              lg={{ size: 6, offset: 1 }}
              xl={{ size: 6, offset: 1 }}
            >
              <FormProfile />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  </PageTransition>
);

export default Profile;
