import moment from "moment";

const today = moment().format("YYYY-MM-DD hh:mm:ss");
const tomorrow = moment()
  .add(1, "days")
  .format("YYYY-MM-DD hh:mm:ss");

const initialState = {
  loading: false,
  newslist: [],
  gifList: {
    loading: false,
    data: []
  },
  emojis: {
    yes: "",
    no: ""
  },
  news: {
    title: "",
    headline: "",
    body: "",
    priority: 1,
    frequency: "1",
    starts_at: today,
    ends_at: tomorrow,
    active: false,
    gif: "",
    api_news: [],
    categories: [],
    genders: [],
    age_range: [],
    actions: [
      {
        name: "",
        action: "view-more",
        weight: 1,
        emoji: null
      },
      {
        name: "",
        action: "pass",
        weight: -1,
        emoji: null
      }
    ]
  },
  links: {
    first: null,
    last: null,
    next: null,
    prev: null
  },
  meta: {
    currentPage: 1,
    from: null,
    last_page: null,
    per_page: null,
    to: null,
    total: null
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_NEWS_LOADING": {
      return {
        ...state,
        loading: true
      };
    }

    case "GET_NEWS_SUCCESS": {
      return {
        ...state,
        loading: false,
        newslist: [...action.payload.newslist],
        links: {
          ...state.links,
          first: action.payload.links.first,
          last: action.payload.links.last,
          next: action.payload.links.next,
          prev: action.payload.links.prev
        },
        meta: {
          ...state.meta,
          currentPage: action.payload.meta.currentPage,
          from: action.payload.meta.from,
          last_page: action.payload.meta.last_page,
          per_page: action.payload.meta.per_page,
          to: action.payload.meta.to,
          total: action.payload.meta.total
        }
      };
    }

    case "SET_API_NEWS": {
      return {
        ...state,
        news: {
          ...state.news,
          api_news: [...state.news.api_news, action.payload.value]
        }
      };
    }

    case "DELETE_API_NEWS": {
      return {
        ...state,
        news: {
          ...state.news,
          api_news: state.news.api_news.filter(
            publisher => publisher.id !== action.payload.value
          )
        }
      };
    }

    case "GET_NEWS_ERROR": {
      return {
        ...state,
        ...initialState
      };
    }

    case "SET_CATEGORIES": {
      return {
        ...state,
        news: {
          ...state.news,
          categories: [...action.payload.value]
        }
      };
    }

    case "SET_TITLE": {
      return {
        ...state,
        news: {
          ...state.news,
          title: action.payload.title,
          headline: action.payload.title
        }
      };
    }

    case "SET_PRIORITY": {
      return {
        ...state,
        news: {
          ...state.news,
          priority: action.payload.value
        }
      };
    }

    case "SET_BODY": {
      return {
        ...state,
        news: {
          ...state.news,
          body: action.payload.value
        }
      };
    }

    case "GET_GIFS_LOADING": {
      return {
        ...state,
        gifList: {
          ...state.gifList,
          loading: true
        }
      };
    }

    case "GET_GIF_SUCCESS": {
      return {
        ...state,
        gifList: {
          ...state.gifList,
          data: action.payload.gifs,
          loading: false
        }
      };
    }

    case "SEARCH_GIF_SUCCESS": {
      return {
        ...state,
        gifList: {
          ...state.gifList,
          data: action.payload.gifs,
          loading: false
        }
      };
    }

    case "SET_GIF": {
      return {
        ...state,
        news: {
          ...state.news,
          gif: action.payload.value
        }
      };
    }

    case "REMOVE_GIF": {
      return {
        ...state,
        news: {
          ...state.news,
          gif: ""
        }
      };
    }

    case "SET_YES_TEXT": {
      const yesAction = state.news.actions[0];

      const modifiedYes = {
        ...yesAction,
        name: action.payload.value
      };

      const updatedState = {
        ...state,
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 0: modifiedYes })
        }
      };

      return updatedState;
    }

    case "SET_YES_EMOJI": {
      const yesAction = state.news.actions[0];

      const modifiedYes = {
        ...yesAction,
        emoji: action.payload.unified
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          yes: `0x${action.payload.unified}`
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 0: modifiedYes })
        }
      };
    }

    case "REMOVE_YES_EMOJI": {
      const yesAction = state.news.actions[0];

      const modifiedYes = {
        ...yesAction,
        emoji: null
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          yes: ""
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 0: modifiedYes })
        }
      };
    }

    case "SET_NO_TEXT": {
      const noAction = state.news.actions[1];

      const modifiedNo = {
        ...noAction,
        name: action.payload.value
      };

      return {
        ...state,
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 1: modifiedNo })
        }
      };
    }

    case "SET_NO_EMOJI": {
      const noAction = state.news.actions[1];

      const modifiedNo = {
        ...noAction,
        emoji: action.payload.unified
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          no: `0x${action.payload.unified}`
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 1: modifiedNo })
        }
      };
    }

    case "REMOVE_NO_EMOJI": {
      const noAction = state.news.actions[1];

      const modifiedNo = {
        ...noAction,
        emoji: null
      };

      return {
        ...state,
        emojis: {
          ...state.emojis,
          no: ""
        },
        news: {
          ...state.news,
          actions: Object.assign([...state.news.actions], { 1: modifiedNo })
        }
      };
    }

    case "ADD_API_NEWS": {
      return {
        ...state,
        news: {
          ...state.news,
          api_news: [...action.payload.apiNews]
        }
      };
    }

    case "SET_FREQUENCY": {
      return {
        ...state,
        news: {
          ...state.news,
          frequency: action.payload.value
        }
      };
    }

    case "SET_GENDER": {
      return {
        ...state,
        news: {
          ...state.news,
          genders: action.payload.value
        }
      };
    }

    case "ADD_BODY_TEXT": {
      const apiNewsCopy = state.news.api_news;

      const updatedApisNews = apiNewsCopy.map(item => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            body: action.payload.body
          };
        }

        return item;
      });

      return {
        ...state,
        news: {
          ...state.news,
          api_news: [...updatedApisNews]
        }
      };
    }

    case "SET_START_AT": {
      return {
        ...state,
        news: {
          ...state.news,
          starts_at: action.payload.value
        }
      };
    }

    case "SET_ENDS_AT": {
      return {
        ...state,
        news: {
          ...state.news,
          ends_at: action.payload.value
        }
      };
    }

    case "DELETE_PUBLISHERS_FROM_TABLE": {
      return {
        ...state,
        selectedPublishers: state.selectedPublishers.filter(
          publisher => publisher.id !== action.payload.id
        ),
        news: {
          ...state.news,
          api_news: state.news.api_news.filter(
            publisher => publisher.id !== action.payload.id
          )
        }
      };
    }

    case "SET_AGE_RANGES": {
      return {
        ...state,
        news: {
          ...state.news,
          age_range: action.payload.value
        }
      };
    }

    case "RESET_NEWS_STATE": {
      return initialState;
    }

    case "RESET_PUBLISHERS_ALL": {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
